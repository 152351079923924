/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Row } from "react-grid-system";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import useConfirmedAction from "../../../../../hooks/useConfirmedAction";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import InsuranceInfo from "./components/InsuranceInfo";
import Selects from "./components/Selects";
import {
  DocumentStages,
  DocumentStatus,
  useGetDocumentRejectionFormResponseLazyQuery,
} from "../../../../../graphql/generated/types";
import { useModalStore } from "../../../../../components/Modal/context/ModalStore";
import Form from "../../../../../modules/Forms";
import FormTitle from "../../../../../modules/Forms/components/FormTitle";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";

const DocumentSummary: FC = () => {
  const {
    documentData: { _id, stage, status, archived },
    handleToggleArchived,
    handleDuplicateDocument,
    handleRenewDocument,
    refetch,
  } = useDocumentDetails();

  const onArchive = useConfirmedAction(
    `Tem certeza de que deseja ${
      archived ? "desarquivar" : "arquivar"
    } este documento?`,
    {
      onConfirm: [{ onClick: handleToggleArchived }],
    }
  );

  const { openModal, closeModal } = useModalStore();
  const [getDocumentRejectionFormResponse] =
    useGetDocumentRejectionFormResponseLazyQuery();
  const handleRejectDocument = async () => {
    const { data } = await getDocumentRejectionFormResponse({
      variables: {
        documentId: _id!,
      },
    });
    openModal(
      <>
        <FormTitle title="Recusar cotação" />
        <Form
          formId="documentRejection"
          docId={data!.getDocumentRejectionFormResponse!.formResponseId!}
          onCancel={closeModal}
          onSubmit={() => {
            refetch(), closeModal();
          }}
        />
      </>
    );
  };

  const currentStage = useDocumentOrder(stage!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);

  const footerButtons = [
    {
      text: archived ? "Desarquivar" : "Arquivar",
      iconName: archived ? IconNames.Unarchive : IconNames.Archive,
      onClick: onArchive,
      disabled: currentStage >= policyStage,
      flag: "admin-only",
    },
    {
      text: "Recusar",
      iconName: IconNames.Rejected,
      onClick: handleRejectDocument,
      disabled:
        status === DocumentStatus.Rejected || currentStage >= policyStage,
      flag: "admin-only",
    },
    {
      text: "Duplicar",
      iconName: IconNames.Duplicate,
      onClick: handleDuplicateDocument,
      flag: "admin-only",
    },
    {
      text: "Renovar",
      iconName: IconNames.Redo,
      onClick: handleRenewDocument,
      flag: "admin-only",
      disabled: currentStage < policyStage,
    },
  ];

  return (
    <DetailsSection
      title="Dados gerais"
      noTitle
      main
      footerButtons={footerButtons}
    >
      <Row>
        <InsuranceInfo />
        <Selects />
      </Row>
    </DetailsSection>
  );
};

export default DocumentSummary;
