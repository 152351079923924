/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import FormLabel from "../../modules/Forms/components/FormLabel";
import TextInput from "../Input/TextInput";
import { DateDisplays } from "../../filters/types";
import { valueToDates, DateOperator, datesToValue } from "./dateFilterUtils";

const dateFormat = "dd/MM/yyyy";

const styles = {
  wrapper: css`
    position: relative;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 5px 5px 0 5px;
    justify-content: space-between;
  `,
  datePickerContainer: css`
    width: 45%;
    > div {
      width: 100%;
    }
  `,
};

export const DateFilter: FC<{
  dateDisplay: DateDisplays;
  onChange: (value: string[]) => void;
  value: string[];
}> = ({ value, onChange, dateDisplay }) => {
  const currentValues = valueToDates(value, dateDisplay);

  const setDate = (type: DateOperator, newDateValue: Date | null) => {
    const newValue = {
      ...currentValues,
      [type]: newDateValue,
    };
    onChange(datesToValue(newValue, dateDisplay));
  };

  return (
    <div css={styles.wrapper}>
      {[
        {
          label: "A partir de:",
          type: DateOperator.gte,
          placeholderText: "Selecione uma data de início",
        },
        {
          label: "Até:",
          type: DateOperator.lte,
          placeholderText: "Selecione uma data de fim",
        },
      ].map(({ label, type, placeholderText }) => (
        <div css={styles.datePickerContainer}>
          <FormLabel>{label}</FormLabel>
          <DatePicker
            customInput={(() => (
              // @ts-ignore
              <TextInput />
            ))()}
            locale={ptBR}
            selected={currentValues[type] || null}
            placeholderText={placeholderText}
            onChange={(value) => setDate(type, value as Date)}
            dateFormat={dateFormat}
            showTimeInput={dateDisplay === DateDisplays.dateWithTime}
          />
        </div>
      ))}
    </div>
  );
};
