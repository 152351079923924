import React, { FC, useState } from "react";
import { formatISODate } from "../../utils/dateUtils";
import TextInput, { TextInputProps } from "./TextInput";

const getValidDate = (date: string) => {
  if (date === "") {
    return "";
  }
  const [day, month, year] = date.split("/");
  const dateObj = new Date(Date.UTC(+year, +month - 1, +day));
  if (
    parseFloat(day) === dateObj.getUTCDate() &&
    parseFloat(month) === dateObj.getUTCMonth() + 1 &&
    parseFloat(year) === dateObj.getUTCFullYear()
  ) {
    return dateObj.toISOString().split("T")[0];
  }
  return null;
};

const formatDateText = (date: string) => {
  const dateDigits = date?.replace(/[^0-9]/g, "") || "";
  const day = dateDigits.slice(0, 2);
  const month = dateDigits.slice(2, 4);
  const year = dateDigits.slice(4, 8);
  return `${day}${month ? "/" + month : ""}${year ? "/" + year : ""}`;
};

const DateInput: FC<
  TextInputProps & { handleChange?: (value: string) => any }
> = ({ value, onChange, handleChange, ...rest }) => {
  const [localValue, setLocalValue] = useState<string>(formatISODate(value));
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    const validDate = getValidDate(e.target.value);
    if (validDate !== null) {
      e.target.value = validDate;
      onChange
        ? onChange(e)
        : handleChange
        ? handleChange(e.target.value)
        : undefined;
    }
  };
  return (
    <TextInput
      value={formatDateText(localValue)}
      onChange={handleDateChange}
      {...rest}
    />
  );
};

export default DateInput;
