import { useEffect, useState } from "react";
import { TableColumn } from "./useResizeableTable";
import { SortOption } from "../components/ResizableTable";
import { SelectInputOptionInterface } from "../components/Input/SelectInputs/SelectInput";
import {
  getLocalStorageTableOptions,
  saveTableOptions,
} from "../utils/localStorageOptions";
import { useAuth } from "./useAuth";
import * as XLSX from "xlsx";

export enum SheetFormat {
  CSV = "csv",
  XLSX = "xlsx",
}

export interface TableActionsContext<C> {
  activeView: string | null;
  selectedColumns: TableColumn<C>[];
  tableColumnsOptions: SelectInputOptionInterface[];
  selectedRows: Record<string, boolean>;
  sort: SortOption;
  onTableSwitchViewClick: (active: string) => void;
  onTableColumnsSelect: (selected: string[]) => void;
  onResetColumnsClick: () => void;
  onSortClick: (id: string) => void;
  onDownloadSheetClick?: (format: SheetFormat) => Promise<void>;
  onCheckboxClick: (id: string) => void;
}

const useTableActions = <C, D extends { _id: string }>(
  allColumns: TableColumn<C>[],
  initialSort: SortOption,
  data: D[],
  tableId: string,
  fetchSheetData?: () => Promise<C[]>
): TableActionsContext<C> & {
  selectedRows: Record<string, boolean>;
  onCheckboxClick: (id: string) => void;
} => {
  const [activeView, setActiveView] = useState<null | string>(null);
  const [selectedColumns, setSelectedColumns] = useState<TableColumn<C>[]>([]);
  const [sort, setSort] = useState<SortOption>(initialSort);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const { user } = useAuth();

  const tableColumnsOptions = allColumns
    .filter(({ id }) => id !== "checkbox")
    .map(({ id, label }) => ({
      value: id as string,
      label,
    }));

  useEffect(() => {
    if (user._id) {
      const options = getLocalStorageTableOptions(user._id, tableId);

      setSelectedColumns(
        options.selectedColumns
          ? allColumns.filter(({ id }) =>
              options.selectedColumns?.includes(id as string)
            )
          : allColumns
      );
      setActiveView(options.activeView || "table");
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      const newRows: Record<string, boolean> = {};
      Object.entries(selectedRows).forEach(([key, selected]) => {
        if (key !== "all") {
          newRows[key] = data.some((doc) => selected && doc._id === key);
        }
      });

      const rowsCount = Object.values(newRows).reduce((prev, value) => {
        return prev + (value ? 1 : 0);
      }, 0);

      if (rowsCount === data.length) {
        newRows.all = true;
      }

      setSelectedRows(newRows);
    }
  }, [data]);

  const onTableSwitchViewClick = (id: string) => {
    setActiveView(id);
    const options = getLocalStorageTableOptions(user._id, tableId);
    options.activeView = id;
    saveTableOptions(user._id, tableId, options);
  };

  const onTableColumnsSelect = (selected: string[]) => {
    const columns = allColumns.filter(
      ({ id }) => selected.includes(id as string) || id === "checkbox"
    );
    setSelectedColumns(columns);
    const options = getLocalStorageTableOptions(user._id, tableId);
    options.selectedColumns = columns.map(({ id }) => id) as string[];
    saveTableOptions(user._id, tableId, options);
  };
  const onResetColumnsClick = () => {
    setSelectedColumns(allColumns);
    const options = getLocalStorageTableOptions(user._id, tableId);
    options.selectedColumns = allColumns.map(({ id }) => id) as string[];
    saveTableOptions(user._id, tableId, options);
  };

  const onSortClick = (id: string) => {
    const col = allColumns.find((c) => c.id === id);
    if (col) {
      const direction = id !== sort.id ? 1 : ((sort.direction * -1) as 1 | -1);

      setSort({
        id: col.id as string,
        sortKey: col.sortKey || (col.id as string),
        direction,
      });
    }
  };

  const onCheckboxClick = (id: string) => {
    const checked = { ...selectedRows };
    if (id !== "all") {
      checked[id] = !selectedRows[id];
    } else {
      data?.forEach(({ _id }) => (checked[_id] = !selectedRows.all));
    }

    const numberOfChecks = Object.keys(checked).reduce((prev, curr) => {
      if (curr !== "all" && checked[curr]) prev += 1;
      return prev;
    }, 0);

    checked.all = numberOfChecks === data?.length;

    setSelectedRows(checked);
  };

  const triggerDownload = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadCSV = (data: Record<string, string>[]) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const csvBlob = new Blob([csv], { type: "text/csv" });
    triggerDownload(csvBlob, "data.csv");
  };

  const downloadXLSX = (data: Record<string, string>[]) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const xlsBlob = new Blob([wbout], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    triggerDownload(xlsBlob, "data.xlsx");
  };
  const onDownloadSheetClick = fetchSheetData
    ? async (format: SheetFormat) => {
        const data = (await fetchSheetData()) as Record<string, string>[];
        const parsedData = data.map((entry) => {
          Object.keys(entry).forEach((key) => {
            const d = entry[key];
            if (d === undefined) {
              entry[key] = "";
            } else if (!isNaN(Number(d))) {
              entry[key] = Number(d).toLocaleString("pt-br");
            }
          });
          return entry;
        });
        if (format === SheetFormat.XLSX) downloadXLSX(parsedData);
        else downloadCSV(parsedData);
      }
    : undefined;

  return {
    activeView,
    selectedColumns,
    selectedRows,
    sort,
    tableColumnsOptions,
    onTableSwitchViewClick,
    onTableColumnsSelect,
    onResetColumnsClick,
    onSortClick,
    onDownloadSheetClick,
    onCheckboxClick,
  };
};

export default useTableActions;
