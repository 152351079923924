import { DetailsRowProps } from "../../components/Details/components/Main/Grid/DetailsRow";
import { DetailsRowValueVariants } from "../../components/Details/components/Main/Grid/DetailsRowValue";
import {
  BasePaymentFieldsFragment,
  Services,
  SubventionType,
} from "../../graphql/generated/types";
import { formatISODate } from "../../utils/dateUtils";
import { formatPrice } from "../../utils/formatNumber";
import { PaymentStatusLabels } from "../Documents/DocumentDetails/sections/Policy/Payments";

export const SubventionTypeLabels = {
  [SubventionType.Federal]: "Federal",
  [SubventionType.State]: "Estadual",
};

export const getPaymentDetailsColumns = (
  payment: BasePaymentFieldsFragment
) => ({
  leftSideColumn: [
    {
      label: "Parcela",
      value: payment.name,
    },
    {
      label: "Tipo de subvenção",
      value: SubventionTypeLabels[payment.subventionType!],
    },
    {
      label: "Seguradora",
      value: payment.provider,
    },
  ] as DetailsRowProps[],
  rightSideColumn: [
    {
      label: "Vencimento",
      value: formatISODate(payment.dateDue),
    },
    {
      label: "Valor",
      value: formatPrice(payment.amount),
    },
    {
      label: "Boleto",
      variant: DetailsRowValueVariants.File,
      value: payment.billFile?.fileKey && {
        file: payment.billFile,
        objectId: payment?._id,
        fieldPath: "billFile",
        serviceName: Services.Payments,
      },
    },
    {
      label: "Status",
      value: PaymentStatusLabels[payment.status],
    },
    {
      label: "Enviado em",
      value: formatISODate(payment.sentAt),
    },
  ] as DetailsRowProps[],
});
