import { FC } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Layout from "../../components/Layout";
import MainFrame from "../../components/MainFrame";
import TableProvider from "../../contexts/table";
import { useUIStore } from "../../hooks/useUIStore";
import { PaymentsLocation } from "../../Locations";
import PaymentsList, {
  mapTableData,
  paymentsTableColumns,
} from "./PaymentsList";
import useModelFilter from "../../hooks/useModelFilter";
import useUrlFilter from "../../hooks/useUrlFilter";
import { paymentFilters } from "./paymentFilters";
import { useListPaymentsLazyQuery } from "../../graphql/generated/types";

const Payments: FC = () => {
  const navigate = useNavigate();
  const { toggleDrawerSize } = useUIStore();
  const filter = useModelFilter(paymentFilters, []);
  useUrlFilter(filter);

  const [listPayments] = useListPaymentsLazyQuery();

  const fetchSheetData = async () => {
    const result = await listPayments({
      variables: {
        filter: filter.composeQuery(),
      },
    });

    if (result.data?.payments) {
      return mapTableData(result.data.payments);
    }

    return [];
  };

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(PaymentsLocation)}
        onToggle={toggleDrawerSize}
        drawerParamKey="paymentId"
        filter={filter}
      >
        <TableProvider
          allColumns={paymentsTableColumns}
          initialSort={{ id: "dateDue", sortKey: "dateDue", direction: -1 }}
          tableId="payments"
          fetchSheetData={fetchSheetData}
        >
          <PaymentsList filter={filter} />
        </TableProvider>
      </MainFrame>
    </Layout>
  );
};

export default Payments;
