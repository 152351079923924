// actions
import Send from "../assets/actions/Send";
import Logout from "../assets/actions/Logout";
import SkipBack from "../assets/actions/SkipBack";
import SkipForward from "../assets/actions/SkipForward";
import Share from "../assets/actions/Share";
import Add from "../assets/actions/Add";
import Delete from "../assets/actions/Delete";
import Edit from "../assets/actions/Edit";
import OverflowMenu from "../assets/actions/OverflowMenu";
import Archive from "../assets/actions/Archive";
import Unarchive from "../assets/actions/Unarchive";
import ChevronDown from "../assets/actions/ChevronDown";
import ChevronUp from "../assets/actions/ChevronUp";
import ChevronLeft from "../assets/actions/ChevronLeft";
import ChevronRight from "../assets/actions/ChevronRight";
import Search from "../assets/actions/Search";
import Close from "../assets/actions/Close";
import Minimize from "../assets/actions/Minimize";
import Maximize from "../assets/actions/Maximize";
import Star from "../assets/actions/Star";
import StarFilled from "../assets/actions/StarFilled";
import Download from "../assets/actions/Download";
import Upload from "../assets/actions/Upload";
import Redo from "../assets/actions/Redo";
import OverflowMenuVertical from "../assets/actions/OverflowMenuVertical";
import Filter from "../assets/actions/Filter";
import Notification from "../assets/actions/Notification";
import Task from "../assets/actions/Task";
import Duplicate from "../assets/actions/Duplicate";

// contact
import Email from "../assets/contact/Email";
import Facebook from "../assets/contact/Facebook";
import Instagram from "../assets/contact/Instagram";
import Linkedin from "../assets/contact/Linkedin";
import Location from "../assets/contact/Location";
import Phone from "../assets/contact/Phone";
import Whatsapp from "../assets/contact/Whatsapp";

// details
import Details from "../assets/details/Details";
import Notes from "../assets/details/Notes";
import Chat from "../assets/details/Chat";

// status
import Done from "../assets/status/Done";
import Expired from "../assets/status/Expired";
import Incomplete from "../assets/status/Incomplete";
import Pending from "../assets/status/Pending";
import Rejected from "../assets/status/Rejected";
import Sent from "../assets/status/Sent";
import Valid from "../assets/status/Valid";
import Scrutiny from "../assets/status/Scrutiny";
import Deal from "../assets/status/Deal";
import Pitch from "../assets/status/Pitch";

// stages
import Proposal from "../assets/stages/proposal";
import Policy from "../assets/stages/policy";
import Claim from "../assets/stages/claim";
import Simulation from "../assets/stages/simulation";

// users
import Avatar from "../assets/users/Avatar";
import AvatarFilled from "../assets/users/AvatarFilled";
import Organization from "../assets/users/Organization";
import Unassigned from "../assets/users/Unassigned";
import User from "../assets/users/User";

// insurances
import Agriculture from "../assets/insurances/agriculture";
import Benefectory from "../assets/insurances/benefectory";
import Company from "../assets/insurances/company";
import Forest from "../assets/insurances/forest";
import Machine from "../assets/insurances/machine";
import Pecuary from "../assets/insurances/pecuary";
import View from "../assets/actions/View";
import Warning from "../assets/status/Warning";
import { Confirm } from "../assets/actions/Confirm";
import Save from "../assets/actions/Save";

export enum IconNames {
  // actions
  Send = "Send",
  Logout = "Logout",
  SkipBack = "SkipBack",
  SkipForward = "SkipForward",
  Share = "Share",
  Add = "Add",
  Delete = "Delete",
  Edit = "Edit",
  OverflowMenu = "OverflowMenu",
  Archive = "Archive",
  Unarchive = "Unarchive",
  ChevronDown = "ChevronDown",
  ChevronUp = "ChevronUp",
  ChevronLeft = "ChevronLeft",
  ChevronRight = "ChevronRight",
  Search = "Search",
  Close = "Close",
  Minimize = "Minimize",
  Maximize = "Maximize",
  Star = "Star",
  StarFilled = "StarFilled",
  Download = "Download",
  Upload = "Upload",
  Redo = "Redo",
  OverflowMenuVertical = "OverflowMenuVertical",
  Filter = "Filter",
  Notification = "Notification",
  Task = "Task",
  View = "View",
  Duplicate = "Duplicate",
  Confirm = "Confirm",
  Save = "Save",

  // contact
  Email = "Email",
  Facebook = "Facebook",
  Instagram = "Instagram",
  Linkedin = "Linkedin",
  Location = "Location",
  Phone = "Phone",
  Whatsapp = "Whatsapp",

  // details
  Details = "Details",
  Notes = "Notes",
  Chat = "Chat",

  // status
  Done = "Done",
  Expired = "Expired",
  Incomplete = "Incomplete",
  Pending = "Pending",
  Rejected = "Rejected",
  Sent = "Sent",
  Valid = "Valid",
  Scrutiny = "Scrutiny",
  Deal = "Deal",
  Pitch = "Pitch",
  Warning = "Warning",

  // stages
  Proposal = "Proposal",
  Policy = "Policy",
  Claim = "Claim",
  Simulation = "Simulation",

  // users
  Avatar = "Avatar",
  AvatarFilled = "AvatarFilled",
  Organization = "Organization",
  Unassigned = "Unassigned",
  User = "User",

  // insurances
  Agriculture = "Agriculture",
  Benefectory = "Benefectory",
  Company = "Company",
  Forest = "Forest",
  Machine = "Machine",
  Pecuary = "Pecuary",
}

export const IconByName = {
  // actions
  [IconNames.Send]: Send,
  [IconNames.Logout]: Logout,
  [IconNames.SkipBack]: SkipBack,
  [IconNames.SkipForward]: SkipForward,
  [IconNames.Share]: Share,
  [IconNames.Add]: Add,
  [IconNames.Delete]: Delete,
  [IconNames.Edit]: Edit,
  [IconNames.OverflowMenu]: OverflowMenu,
  [IconNames.Archive]: Archive,
  [IconNames.Unarchive]: Unarchive,
  [IconNames.ChevronDown]: ChevronDown,
  [IconNames.ChevronUp]: ChevronUp,
  [IconNames.ChevronLeft]: ChevronLeft,
  [IconNames.ChevronRight]: ChevronRight,
  [IconNames.Search]: Search,
  [IconNames.Close]: Close,
  [IconNames.Minimize]: Minimize,
  [IconNames.Maximize]: Maximize,
  [IconNames.Star]: Star,
  [IconNames.StarFilled]: StarFilled,
  [IconNames.Download]: Download,
  [IconNames.Upload]: Upload,
  [IconNames.Redo]: Redo,
  [IconNames.OverflowMenuVertical]: OverflowMenuVertical,
  [IconNames.Notification]: Notification,
  [IconNames.Filter]: Filter,
  [IconNames.Task]: Task,
  [IconNames.View]: View,
  [IconNames.Duplicate]: Duplicate,
  [IconNames.Confirm]: Confirm,
  [IconNames.Save]: Save,

  // contact
  [IconNames.Email]: Email,
  [IconNames.Facebook]: Facebook,
  [IconNames.Instagram]: Instagram,
  [IconNames.Linkedin]: Linkedin,
  [IconNames.Location]: Location,
  [IconNames.Phone]: Phone,
  [IconNames.Whatsapp]: Whatsapp,

  // details
  [IconNames.Details]: Details,
  [IconNames.Notes]: Notes,
  [IconNames.Chat]: Chat,

  // status
  [IconNames.Done]: Done,
  [IconNames.Expired]: Expired,
  [IconNames.Incomplete]: Incomplete,
  [IconNames.Pending]: Pending,
  [IconNames.Rejected]: Rejected,
  [IconNames.Sent]: Sent,
  [IconNames.Valid]: Valid,
  [IconNames.Scrutiny]: Scrutiny,
  [IconNames.Deal]: Deal,
  [IconNames.Pitch]: Pitch,
  [IconNames.Warning]: Warning,

  // stages
  [IconNames.Proposal]: Proposal,
  [IconNames.Policy]: Policy,
  [IconNames.Claim]: Claim,
  [IconNames.Simulation]: Simulation,

  // users
  [IconNames.Avatar]: Avatar,
  [IconNames.AvatarFilled]: AvatarFilled,
  [IconNames.Organization]: Organization,
  [IconNames.Unassigned]: Unassigned,
  [IconNames.User]: User,

  // insurances
  [IconNames.Agriculture]: Agriculture,
  [IconNames.Benefectory]: Benefectory,
  [IconNames.Company]: Company,
  [IconNames.Forest]: Forest,
  [IconNames.Machine]: Machine,
  [IconNames.Pecuary]: Pecuary,
};
