/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import MultiSelectInput from "../../Input/SelectInputs/MultiSelectInput";
import Button from "../../Button";
import { IconNames } from "../../Icons/styles/iconNames";
import Icon from "../../Icons";
import { useModalStore } from "../../Modal/context/ModalStore";
import { useTableContext } from "../../../contexts/table";
import ActionList from "../../ActionsList";
import { useFlags } from "../../../hooks/useFlags";
import { SheetFormat } from "../../../hooks/useTableActions";

interface TableActionsProps {
  uploadModal?: React.ReactNode;
  bulkActions?: {
    label: string;
    onClick: () => void;
  }[];
}

const getStyles = () => ({
  container: css`
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
  `,
  right: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  resetButton: css`
    position: relative;
    > span {
      transform: scaleX(-1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    svg {
      cursor: pointer;
    }
  `,
});

const TableActions: React.FC<TableActionsProps> = ({
  uploadModal,
  bulkActions,
}) => {
  const styles = getStyles();
  const { openModal } = useModalStore();
  const { actions } = useTableContext();
  const {
    activeView,
    selectedColumns,
    tableColumnsOptions,
    selectedRows,
    onTableColumnsSelect,
    onResetColumnsClick,
    onDownloadSheetClick,
  } = actions;

  const nSelectedRows = Object.keys(selectedRows).reduce(
    (prev, key) => prev + (selectedRows[key] && key !== "all" ? 1 : 0),
    0
  );

  const { isFlagEnabled } = useFlags();
  const isAdmin = isFlagEnabled("admin-only");

  return (
    <div css={styles.container}>
      <div css={styles.right}>
        {isAdmin && bulkActions ? (
          <ActionList
            label={`AÇÕES (${nSelectedRows} SELECIONADOS)`}
            actions={bulkActions}
          />
        ) : null}

        {isAdmin && uploadModal ? (
          <Button
            text="Upload"
            width={130}
            iconName={IconNames.Upload}
            onClick={() =>
              openModal(uploadModal, undefined, {
                styles: {
                  container: {
                    height: 430,
                    width: 500,
                    top: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",
                  },
                },
              })
            }
          />
        ) : null}

        {onDownloadSheetClick && (
          <ActionList
            label={"EXPORTAR"}
            actions={[
              {
                label: "CSV",
                onClick: () => onDownloadSheetClick(SheetFormat.CSV),
              },
              {
                label: "XLSX",
                onClick: () => onDownloadSheetClick(SheetFormat.XLSX),
              },
            ]}
          />
        )}
        {activeView === "table" ? (
          <>
            <MultiSelectInput
              onSelect={onTableColumnsSelect}
              value={selectedColumns.map(({ id }) => id)}
              inputName="columnsSelect"
              placeholder="Vizualisar Colunas"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              options={tableColumnsOptions}
            />
            <Button
              customStyle={styles.resetButton}
              width={45}
              icon={<Icon name={IconNames.Redo} />}
              onClick={onResetColumnsClick}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TableActions;
