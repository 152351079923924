/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { theme } from "../../theme";
import { TableColumn } from "../../hooks/useResizeableTable";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import cls from "classnames";
import Checkbox from "../Input/CheckboxInput";
import { useTableContext } from "../../contexts/table";
import { useNavigate } from "react-router-dom";
import TableCell from "./components/cells/TableCell";
import SkeletonTable from "./components/SkeletonTable";

export interface SortOption {
  id: string;
  sortKey: string;
  direction: 1 | -1;
}

export interface ResizableTableProps<T> {
  tableData: T[];
  loading: boolean;
  redirectLocation: (id: string) => string;
}

const getStyles = () => ({
  container: css`
    width: 100%;
    height: calc(100vh - 296px);
    overflow-y: auto;
    overflow-x: hidden;
  `,
  table: css`
    width: fit-content;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    thead {
      top: 0;
      z-index: 1;
      position: sticky;
    }

    tbody tr {
      cursor: pointer;

      &:hover {
        background-color: ${theme.colors.Grey[20]};
      }
    }

    tr {
      width: fit-content;
      height: 30px;
    }

    th {
      padding: 2px 4px;
      padding-right: 30px;
      position: relative;
      font-weight: bold;
      height: 30px;
      box-sizing: border-box;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      background-color: white;

      ::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: black;
      }

      :hover .resizer {
        opacity: 1;
      }

      :last-child {
        .resizer {
          display: none;
        }
      }
    }

    td {
      border-bottom: 1px solid ${theme.colors.Grey[40]};
      padding: 5px 10px 0 0;
    }
  `,
  sortButton: css`
    position: absolute;
    top: 3px;
    bottom: 0;
    right: 5px;
    opacity: 0.3;
    svg {
      cursor: pointer;
    }

    &.selected {
      opacity: 1;
    }

    &.desc {
      transform: rotate(180deg);
    }
  `,
  resizer: css`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    background: ${theme.colors.Green[60]};
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    opacity: 0;
  `,
});

const ResizableTable = <T extends { id: string }>({
  tableData,
  loading,
  redirectLocation,
}: ResizableTableProps<T>) => {
  const styles = getStyles();
  const { resize, actions } = useTableContext();
  const navigate = useNavigate();

  const { tableRef, columnWidths, handleMouseDown } = resize;
  const {
    selectedRows,
    onCheckboxClick,
    selectedColumns: columns,
    sort,
    onSortClick,
  } = actions;

  const renderCell = (row: T, col: TableColumn<T>) => {
    const value = row[col.id] as string | undefined;
    const key = `${String(col.id)}-${row.id}`;
    return col.cell ? (
      col.cell(key, value)
    ) : (
      <TableCell key={key}>{value}</TableCell>
    );
  };

  const renderCheckbox = (id: string, disabled?: boolean) => {
    return (
      <td
        key={`checkobx-${id}`}
        onClick={(e) => {
          e.stopPropagation();
          onCheckboxClick(id);
        }}
      >
        <Checkbox checked={selectedRows[id]} label="" disabled={!!disabled} />
      </td>
    );
  };

  return (
    <div css={styles.container}>
      <table ref={tableRef} css={styles.table}>
        <thead>
          <tr>
            {columns.map((header, i) => {
              return (
                <th
                  key={header.id as string}
                  style={{
                    width: columnWidths[header.id],
                  }}
                >
                  {i === 0 ? (
                    <Checkbox
                      onChange={() => onCheckboxClick("all")}
                      checked={selectedRows.all}
                      label=""
                    />
                  ) : (
                    <>
                      {header.label.toUpperCase()}
                      {!header.disableSort ? (
                        <div
                          onClick={() => onSortClick(header.id as string)}
                          css={styles.sortButton}
                          className={cls({
                            selected: sort.id === header.id,
                            desc:
                              sort.id === header.id && sort.direction === -1,
                          })}
                        >
                          <Icon name={IconNames.ChevronDown} />
                        </div>
                      ) : null}
                    </>
                  )}
                  <div
                    onMouseDown={(e) => {
                      handleMouseDown(e, header.id);
                    }}
                    css={styles.resizer}
                    className="resizer"
                  />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!loading &&
            tableData.map((row, i) => (
              <tr
                key={i}
                onClick={() => {
                  navigate(redirectLocation(row.id));
                }}
              >
                {columns.map((col, j) =>
                  j === 0 ? renderCheckbox(row.id) : renderCell(row, col)
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {loading && <SkeletonTable nColumns={columns.length} />}
    </div>
  );
};

export default ResizableTable;
