/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import {
  BasicCoverageTypes,
  CoverageEvent,
  Crop,
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
  Result,
  ResultCoverage,
} from "../../../../../graphql/generated/types";
import ProviderLogo from "../../../../../components/ProviderLogos";
import QuotationCardSection from "./QuotationCardSection";
import { IQuotationCardRow } from "./QuotationCardRow";
import { formatNumber, formatPrice } from "../../../../../utils/formatNumber";
import { ColorNames, theme } from "../../../../../theme";
import Typography from "../../../../../components/Typography";
import Button from "../../../../../components/Button";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Row from "../../../../../components/Grid/Row";
import QuotationPoints from "./QuotationPoints";
import { useUIStore } from "../../../../../hooks/useUIStore";
import useConfirmedAction from "../../../../../hooks/useConfirmedAction";
import { useFlags } from "../../../../../hooks/useFlags";
import { UnitSystemLabels } from "../../../../../shared/culture/unitSystem";
import { claimRegulationLabels } from "../../../../../shared/results/claimRegulations";
import Column from "../../../../../components/Grid/Column";
import { useQuotationCardColumns } from ".";
import Sup from "../../../../../components/Sup";

interface QuotationCardContentProps {
  result: Result;
  directDocument?: boolean;
  crop?: Crop;
}

const BasicCoverageTypeLabels = {
  [BasicCoverageTypes.Custeio]: "Multirrisco, Custeio",
  [BasicCoverageTypes.Produtividade]: "Multirrisco, Produtividade",
  [BasicCoverageTypes.RiscoNomeado]: "Risco Nomeado",
  [BasicCoverageTypes.Parametrico]: "Paramétrico",
};

const styles = {
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
  },
  logo: {
    paddingLeft: "8px",
    textAlign: "left" as const,
  },
  coverageType: {
    padding: "0px 20px",
  },
  row: {
    marginTop: 12,
  },
};

const eventsMatch = (events1: CoverageEvent[], events2: CoverageEvent[]) =>
  events1.length &&
  events1.length === events2.length &&
  events1
    .map(({ _id }) => _id!)
    .every((eventId) => events2.map(({ _id }) => _id!).includes(eventId));

const QuotationCardContent: FC<QuotationCardContentProps> = ({
  result,
  directDocument,
  crop,
}) => {
  const {
    documentData: { stage, status },
    handleApproveResult,
    getObservationIndex,
  } = useDocumentDetails();

  const { openSubmittedModal, closeSubmittedModal } = useUIStore();

  const showApprovedQuotationMessage = () => {
    openSubmittedModal("Notificação enviada com sucesso!");
    const interval = setTimeout(() => {
      closeSubmittedModal();
    }, 3000);
    return () => clearInterval(interval);
  };

  const handleApproveQuotationDocument = useConfirmedAction(
    "Deseja aprovar a cotação?",
    {
      onConfirm: [
        {
          text: "Sim",
          onClick: async () => {
            await handleApproveResult(result._id!);
            showApprovedQuotationMessage();
          },
        },
        {
          text: "Sim, sem notificar",
          onClick: () => handleApproveResult(result._id!, true),
        },
      ],
    }
  );

  const subventionObs = getObservationIndex("subventionWarning");

  const parametricWarning = getObservationIndex("parametricWarning");
  const coffeeWarning = getObservationIndex("coffeeWarning");
  const priceWarning = coffeeWarning || parametricWarning;

  const coverageTypeInfo = useMemo(() => {
    if (!result.basicCoverageType) return [];
    return [
      {
        label: "Produtividade",
        value: result.guaranteedProductivity
          ? `${formatNumber(result.guaranteedProductivity, 2, 2)} ${
              UnitSystemLabels[crop!.culture?.unitOfMeasure!]
            }/ha`
          : "-",
      },
      {
        label: "Valor unitário",
        value: result.sackValue
          ? `${formatPrice(result.sackValue)}/${
              UnitSystemLabels[crop!.culture?.unitOfMeasure!]
            }`
          : "-",
      },
      {
        label: "Regulação do Sinistro",
        value: result.claimRegulation
          ? claimRegulationLabels[result.claimRegulation]
          : "-",
      },
    ].filter(Boolean) as IQuotationCardRow[];
  }, [
    result.guaranteedProductivity,
    result.basicCoverageType,
    result.sackValue,
    result.claimRegulation,
  ]);

  const itens = useMemo<IQuotationCardRow[][]>(() => {
    return result.itens?.map(({ coverages, name }, index) => {
      const uniqueCoverages: ResultCoverage[] = [];
      coverages.forEach((coverage) => {
        const { coverageEvents, lmi } = coverage;
        const existingCoverage = uniqueCoverages.find(
          ({ coverageEvents: existingCoverageEvents }) =>
            eventsMatch(coverageEvents!, existingCoverageEvents!)
        );
        if (existingCoverage) {
          existingCoverage.lmi = existingCoverage.lmi! + lmi!;
        } else {
          uniqueCoverages.push({ ...coverage! });
        }
      });
      const rows = uniqueCoverages.map((coverage) => {
        return {
          label: (
            <>
              {`${coverage.coverageEvents!.map(({ name }) => name).join("/")}`}
              <Sup>{priceWarning}</Sup>
            </>
          ),
          value: formatPrice(coverage?.lmi),
        };
      });
      return [
        result.itens!.length > 1 && {
          label: `Item ${index + 1}: ${name}`,
          textBold: true,
          uppercase: true,
          textColor: ColorNames.BLACK,
        },
        {
          label: "Cobertura",
          value: "Valor Segurado",
          textBold: true,
          uppercase: true,
          textColor: ColorNames.GREEN,
        },
        ...rows!,
      ].filter(Boolean) as IQuotationCardRow[];
    })!;
  }, [result.itens]);

  const itemsCost = useMemo(() => {
    return result?.itens?.map((item) => {
      return [
        {
          label: "Custo Total",
          value: formatPrice(item.totalPremium),
          textBold: true,
          textColor: ColorNames.BLACK,
          uppercase: true,
        },
      ];
    });
  }, [result.itens]);

  const costs = useMemo(() => {
    return [
      {
        label: "Custo Total",
        value: formatPrice(result.amounts?.totalPremium),
        textBold: true,
        textColor: ColorNames.BLACK,
        uppercase: true,
      },
      {
        label: (
          <>
            Subvenção Estadual<Sup>{subventionObs}</Sup>
          </>
        ),
        value: `- ${formatPrice(result.amounts?.stateSubvention)}`,
        textColor: ColorNames.BLACK,
      },
      {
        label: (
          <>
            Subvenção Federal<Sup>{subventionObs}</Sup>
          </>
        ),
        value: `- ${formatPrice(result.amounts?.federalSubvention)}`,
        textColor: ColorNames.BLACK,
      },
    ] as IQuotationCardRow[];
  }, [result.amounts]);

  const payment = useMemo(() => {
    // Find payment option with most installments without interest (where totalPrice matches first option)
    const basePrice = result.amounts!.paymentOptions![0].totalPrice;
    const paymentOptions = result.amounts!.paymentOptions!;

    const maxNoInterestOption =
      paymentOptions.length === 1
        ? null
        : [...paymentOptions.slice(1)]
            .reverse()
            .find((option) => option.totalPrice === basePrice);

    // Find payment option with most installments that has interest
    const maxInterestOption =
      [...paymentOptions.slice(1)]
        .reverse()
        .find((option) => option.totalPrice > basePrice) || null;
    return [
      {
        label: (
          <>
            Custo Produtor<Sup>{priceWarning}</Sup>
          </>
        ),
        value: `${formatPrice(result.amounts?.finalPremium)} à vista`,
        textLarge: true,
        textBold: true,
      },
      maxNoInterestOption && {
        label: "Pagamento",
        value: `até ${maxNoInterestOption.installmentCount}x ${formatPrice(
          maxNoInterestOption.installmentPrice
        )} sem juros`,
        textLarge: true,
        textBold: true,
      },
      maxInterestOption && {
        label: !maxNoInterestOption ? "Pagamento" : "",
        value: `até ${maxInterestOption.installmentCount}x ${formatPrice(
          maxInterestOption.installmentPrice
        )} com juros`,
        textLarge: true,
        textBold: true,
      },
    ].filter(Boolean) as IQuotationCardRow[];
  }, [result.amounts]);

  const approvable = useMemo(() => {
    return (
      stage === DocumentStages.Simulation && status === DocumentStatus.Sent
    );
  }, [stage, status]);

  const { isFlagEnabled } = useFlags();

  const showQuotationPoints = useMemo(() => {
    return (
      isFlagEnabled("admin-only") &&
      !!result.points?.totalPremium &&
      result.insuranceTypeGroup !== InsuranceTypeGroupEnum.Agricultural
    );
  }, [result.points, isFlagEnabled]);

  return (
    <div css={styles.container}>
      <div>
        <Row
          align="center"
          justify="between"
          noGutters
          noMargins
          css={styles.row}
        >
          <div css={styles.logo}>
            <ProviderLogo name={result.provider!} />
          </div>
          {showQuotationPoints && <QuotationPoints points={result.points!} />}
        </Row>
        <Typography
          variant="textLarge"
          uppercase
          weight="bold"
          component="div"
          align="left"
          css={styles.coverageType}
        >
          {BasicCoverageTypeLabels[result.basicCoverageType!]}
        </Typography>
        {!!coverageTypeInfo.length && (
          <QuotationCardSection rows={coverageTypeInfo} />
        )}
        <Row justify="end" align="end" noGutters noMargins>
          {itens?.map((itensRows, index) => (
            <Column
              xs={12}
              xl={useQuotationCardColumns(result) ? 6 : 12}
              xxl={useQuotationCardColumns(result) ? 4 : 12}
              key={`itemRows-${index}`}
            >
              <QuotationCardSection rows={itensRows} />
              {useQuotationCardColumns(result) && itemsCost && (
                <>
                  <QuotationCardSection
                    rows={itemsCost[index]}
                    backgroundColor={theme.colors.LightGrey[100]}
                  />
                  <br />
                </>
              )}
            </Column>
          ))}
          <Column
            xs={12}
            xl={useQuotationCardColumns(result) ? 6 : 12}
            xxl={useQuotationCardColumns(result) ? 4 : 12}
          >
            <QuotationCardSection
              rows={costs}
              backgroundColor={theme.colors.Grey[20]}
            />
            <br />
            <QuotationCardSection
              rows={payment}
              backgroundColor={theme.colors.Green[20]}
            />
            {approvable && (
              <Row noMargins>
                <Column>
                  <br />
                  <Button
                    text="Aprovar"
                    onClick={
                      directDocument
                        ? () => handleApproveResult(result._id!)
                        : () => handleApproveQuotationDocument()
                    }
                  />
                </Column>
              </Row>
            )}
          </Column>
        </Row>
      </div>
    </div>
  );
};

export default QuotationCardContent;
