//public site
export const PublicSiteLocation =
  process.env.REACT_APP_PUBLIC_SITE_BASE_URL || "https://www.prismaagro.com.br";

//main pages
export const HomeLocation = "/";
export const LoginLocation = "/login";
export const ToLoginLocationWithRedirect = (redirectPath: string) =>
  `/login?redirect=${redirectPath}`;
export const RegisterLocation = "/cadastro";
export const PendingRegisterLocation = "/cadastro/pendente";
export const TermsAndConditionsLocation = "/termos-e-condicoes";
export const SharedFormLocation = "/:slug";

//dashboards
export const DocumentsDashboardLocation = "/seguros";
export const PartnersDashboardLocation = "/parceiros";
export const OrgRegisterLocation = "/cadastro/organizacao";
export const OrgCompleteRegisterLocation = "/cadastro/organizacao/finalizar";

//documents
export const DocumentsLocation = "/seguros/documentos";
export const DocumentDetailsLocation = "/seguros/documentos/:docId";
export const ToDocumentDetailsLocation = (docId: string) =>
  `/seguros/documentos/${docId}`;
export const DocumentDetailsNotesTabLocation =
  "/seguros/documentos/:docId/notas";
export const DocumentDetailsChatTabLocation = "/seguros/documentos/:docId/chat";

//direct documents
export const DirectDocumentDetailsLocation = "/documentos/:docId";
export const ToDirectDocumentDetailsLocation = (docId: string) =>
  `/documentos/${docId}`;

// Complementary Data
export const ComplementaryDataFormLocation =
  "/dados-complementares/:formResponseId";
export const toComplementaryDataFormLocation = (
  formResponseId: string,
  token: string
) => `/dados-complementares/${formResponseId}?token=${token}`;

// Nfe Upload
export const NfeUploadFormLocation = "/notas-fiscais/:formResponseId";
export const toNfeUploadFormLocation = (
  formResponseId: string,
  token: string
) => `/notas-fiscais/${formResponseId}?token=${token}`;

// KML upload
export const KmlUploadFormLocation = "/kml-upload/:formResponseId";
export const toKmlUploadFormLocation = (
  formResponseId: string,
  token: string
) => `/kml-upload/${formResponseId}?token=${token}`;

//simulations
export const SimulationLocation = "/seguros/documentos/form/:formId/:docId";
export const ToSimulationLocation = (docId: string, formId: string) =>
  `/seguros/documentos/form/${formId}/${docId}`;

//direct simulations
export const DirectSimulationsLocation = "/simulacoes";
export const DirectSimulationLocation = "/simulacoes/form/:formId/:docId";
export const ToDirectSimulationLocation = (docId: string, formId: string) =>
  `/simulacoes/form/${formId}/${docId}`;

//direct quotations
export const DirectQuotationsLocation = "/cotacoes";
export const DirectQuotationFormLocation = "/cotacoes/form/:formId/:docId";

// Configurações
export const CreateCultureLocation = "/culturas/nova";
export const CultureListLocation = "/culturas";
export const CultureDetailLocation = "/culturas/:cultureId";
export const toCultureDetailsLocation = (cultureId: string) =>
  CultureDetailLocation.replace(":cultureId", cultureId);

// Contacts
export const ContactsLocation = "/contatos";
export const ContactDetailsLocation = "/contatos/:contactId";
export const ContactDetailsChatLocation = "/contatos/:contactId/chat";
export const ContactDetailsNotesLocation = "/contatos/:contactId/notas";
export const ToContactDetailsLocation = (contactId: string) =>
  ContactDetailsLocation.replace(":contactId", contactId);
export const NewContactLocation = "/contatos/novo";

//Users
export const ConsultantsLocation = "/contatos?tipo=consultor";
export const ConsultantRejectionLocation = "/contatos/:contactId/reject";
export const ToConsultantRejectionLocation = (contactId: string) =>
  ConsultantRejectionLocation.replace(":contactId", contactId);

// conversations
export const ContactConversationLocation = "/contatos/:contactId/conversa";
export const ToContactConversationLocation = (contactId: string) =>
  ContactConversationLocation.replace(":contactId", contactId);

// orgs
export const MyOrgLocation = "/minha-organizacao";

// payments
export const PaymentsLocation = "/pagamentos";
export const PaymentDetailsLocation = "/pagamentos/:paymentId";
export const ToPaymentDetailsLocation = (paymentId: string) =>
  PaymentDetailsLocation.replace(":paymentId", paymentId);
