import { DocumentStatus } from "../../../graphql/generated/types";
import { TModelFilter } from "../../../hooks/useModelFilter";

export const getFilterWithDefaults = (filter: TModelFilter) => {
  const finalFilter = filter.composeQuery();
  const statusFilter = filter.getFilter("status");
  if (!statusFilter.value.length) {
    const filterStatus: { status: DocumentStatus }[] = [];
    const defaultInvisibleStatuses = [
      DocumentStatus.Cancelled,
      DocumentStatus.Rejected,
    ];
    const validStatuses = Object.values(DocumentStatus).filter(
      (s) => !defaultInvisibleStatuses.includes(s)
    );
    validStatuses.forEach((status) => {
      filterStatus.push({
        status,
      } as any);
    });
    finalFilter.push(filterStatus);
  }
  return finalFilter;
};
