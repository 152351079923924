import { Chart as ChartJS, LegendItem } from "chart.js";

export const generateSliceLabel = (
  label: string,
  value: number,
  total: number,
  includePercentage: boolean = false,
  formatCallback?: (value: number) => string
): string => {
  const formattedValue = formatCallback ? formatCallback(value) : value;
  const percentage = total ? ((value / total) * 100).toFixed(2) : "0";
  return includePercentage
    ? `${label}: ${formattedValue} (${percentage}%)`
    : `${label}: ${formattedValue}`;
};

export const generateLegendLabels = (
  chart: ChartJS,
  formatCallback?: (value: number) => string,
  maxLabelLength: number = 12,
  sliceLimit: number = 3
) => {
  const data = chart.data;
  const dataset = data.datasets[0];
  const rawData = dataset.data as number[];
  const total = rawData.reduce((acc, curr) => acc + curr, 0);

  const sortedIndices = rawData
    .map((value, index) => ({ value, index }))
    .sort((a, b) => b.value - a.value)
    .slice(0, sliceLimit)
    .map((item) => item.index);

  return sortedIndices.map((index) => {
    const label = data.labels?.[index] as string;
    const truncatedLabel =
      label.length > maxLabelLength
        ? `${label.slice(0, maxLabelLength - 3)}...`
        : label;
    const displayLabel = generateSliceLabel(
      truncatedLabel,
      rawData[index],
      total,
      false,
      formatCallback
    );

    return {
      text: displayLabel,
      fillStyle: dataset.backgroundColor
        ? (dataset.backgroundColor as any)[index]
        : undefined,
      hidden: !chart.getDataVisibility(index),
      index,
      strokeStyle: "transparent",
      lineWidth: 0,
    } as LegendItem;
  });
};
