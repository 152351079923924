import React, { FC } from "react";
import { CSSObjectWithLabel } from "react-select";
import Select, { SingleValue, components } from "react-select";
import { ColorNames } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { BaseFontSize } from "../Typography";

const customSelectStyles = {
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: "inline-flex",
    alignItems: "center",
    padding: "4px 12px",
    border: "1px solid #dddddd",
    borderRadius: "24px",
  }),
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    minHeight: "auto",
    width: "100%",
    "&:hover": {
      border: "none",
    },
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 4,
    fontWeight: "bold",
    color: "#000",
    textAlign: "left",
    fontSize: "24px",
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: "#000",
    margin: 0,
    padding: 0,
    fontWeight: "normal",
    fontSize: "16px",
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: "#000",
    margin: 0,
    padding: 0,
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    padding: 0,
    transition: "transform 0.2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : undefined,
    color: "#000",
    "> svg": {
      width: "22px",
      height: "22px",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: "1px solid black",
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "4px",
    padding: "6px",
    minWidth: "200px",
  }),
  option: (provided: CSSObjectWithLabel, state: any) => ({
    ...provided,
    padding: "10px 20px",
    fontSize: "16px",
    color: "#000",
    fontWeight: state.isSelected ? "bold" : "normal",
    backgroundColor: state.isFocused ? "#e2e2e2" : "#fff",
    cursor: "pointer",
    "&:active": {
      backgroundColor: "#ebebeb",
    },
  }),
};

export type FilterOption = {
  value: string;
  label: string;
};

export const FilterSelect: FC<{
  onDoubleClick: () => void;
  value: FilterOption | null;
  options: FilterOption[];
  onChange: (newValue: SingleValue<FilterOption>) => void;
}> = ({ onDoubleClick, value, options, onChange }) => {
  return (
    <div onDoubleClick={onDoubleClick}>
      <Select
        placeholder="Selecione um filtro rápido"
        styles={customSelectStyles as any}
        options={options}
        value={value}
        onChange={onChange}
        // We'll disable the built-in clear:
        isClearable={false}
        components={{
          // Left side arrow
          Control: SelectControl,
        }}
        isSearchable={false}
      />
    </div>
  );
};

const SelectControl = (props: any) => {
  const { children, ...rest } = props;
  const orderedChildren = React.Children.toArray(children);
  if (orderedChildren.length === 2) {
    const [valueContainer, indicatorsContainer] = orderedChildren;
    return (
      <components.Control {...rest}>
        <div style={{ marginRight: 5 }}>{indicatorsContainer}</div>
        {valueContainer}
        {props.getValue().length ? (
          <div style={{ marginLeft: "5px", paddingTop: 5 }}>
            <Icon
              name={IconNames.Close}
              size={BaseFontSize * 1.1}
              onClick={() => {
                props.clearValue();
              }}
              color={ColorNames.BLACK}
            />
          </div>
        ) : null}
      </components.Control>
    );
  }
  return <components.Control {...rest}>{children}</components.Control>;
};
