/** @jsxImportSource @emotion/react */

import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import { useMemo } from "react";
import EditableSection from "../../../../components/EditableSection";
import {
  DocumentStages,
  DocumentStatus,
  GetEditCropOnDocumentFormResponseDocument,
  GetEditCropOnDocumentFormResponseQueryVariables,
  Services,
} from "../../../../graphql/generated/types";
import useDocumentOrder from "../../../../hooks/useDocumentOrder";
import Table from "../../../../components/Table";
import DetailsSectionColumns from "../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { DetailsRowValueVariants } from "../../../../components/Details/components/Main/Grid/DetailsRowValue";
import Map from "../../../../components/Map";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import { LatLng } from "../../../../types";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { formatISODate } from "../../../../utils/dateUtils";
import { DetailsRowProps } from "../../../../components/Details/components/Main/Grid/DetailsRow";
import FormWarning from "../../../../modules/Forms/components/FormWarning";
import { plantMethodsLabels } from "../../../../shared/crop/plantMethods";

const CropData: React.FC = () => {
  const {
    documentData: { crop: cropData, kmlFile, _id: documentId, stage },
    refetch,
    handleCopyKmlFileUploadFormLink,
  } = useDocumentDetails();

  const cropInfo = useMemo(() => {
    const kml = [
      cropData?.kmlFile || cropData?.plots?.length! > 0
        ? {
            label: "Arquivo KML",
            variant: DetailsRowValueVariants.File,
            value: {
              file: {
                fileName: cropData?.kmlFile
                  ? cropData.kmlFile.fileName
                  : "Gerar Arquivo",
              },
              objectId: cropData?._id,
              fieldPath: "kmlFile",
              serviceName: Services.Crops,
            },
          }
        : null,
    ].filter(Boolean) as any;
    const culture = [
      {
        label: "Cultura",
        value: cropData?.cultureName,
      },
      {
        label: "Variedade predominante",
        value: cropData?.cultureDetail?.name || "não aplicável",
      },
      cropData?.averagePlantSpacing && {
        label: "Esp. Plantas",
        value: cropData?.averagePlantSpacing,
      },
      cropData?.averageLineSpacing && {
        label: "Esp. Linhas",
        value: cropData?.averageLineSpacing,
      },
    ].filter(Boolean) as DetailsRowProps[];
    const other = [
      {
        label: "Área total",
        value: `${cropData?.area} ha`,
      },
      {
        label: "Data de Plantio",
        value: cropData?.plantDate ? formatISODate(cropData.plantDate) : "-",
      },
      {
        label: "Ano do Plantio",
        value: `${cropData?.cropYear}º`,
      },
      {
        label: "Método do Plantio",
        value: cropData?.plantMethod
          ? plantMethodsLabels[cropData.plantMethod]
          : "-",
      },
      { label: "Safra", value: cropData?.harvest?.name || "-" },
    ];
    return [[...culture, ...kml], other];
  }, [cropData, kmlFile]);

  const currentStage = useDocumentOrder(stage!);
  const doneProposalStage = useDocumentOrder(
    DocumentStages.Proposal,
    DocumentStatus.Done
  );

  const editQuery = useMemo(
    () =>
      currentStage < doneProposalStage
        ? GetEditCropOnDocumentFormResponseDocument
        : undefined,
    [currentStage, doneProposalStage]
  );

  const plots = useMemo(
    () =>
      cropData?.plots?.map((plot) => ({
        ...plot,
        soilType: plot.soilType?.label,
        cultureDetail: plot.cultureDetail?.name,
      })),
    [cropData?.plots]
  );

  const polygons = useMemo(() => {
    return plots?.map((plot) => plot.polygon as LatLng[]);
  }, [plots]);

  const centerMapCoordinates = useMemo(() => {
    if (polygons) {
      const allPoints = polygons.flat();
      const sum = allPoints.reduce(
        (acc, point) => {
          acc.lat += point.lat;
          acc.lng += point.lng;
          return acc;
        },
        { lat: 0, lng: 0 }
      );
      const count = allPoints?.length;
      return {
        lat: count > 0 ? sum.lat / count : 0,
        lng: count > 0 ? sum.lng / count : 0,
      };
    }
  }, [polygons]);

  const footerButtons = useMemo(() => {
    if (!cropData?.kmlFile?.fileName && currentStage < doneProposalStage) {
      return [
        {
          text: "Form do arquivo kml",
          iconName: IconNames.Share,
          onClick: handleCopyKmlFileUploadFormLink,
        },
      ];
    }
  }, [cropData?.kmlFile, currentStage]);

  return (
    <EditableSection<{}, GetEditCropOnDocumentFormResponseQueryVariables>
      title="Dados da Lavoura"
      main
      footerButtons={footerButtons}
      editQuery={editQuery}
      editPayload={{ documentId: documentId!, objectId: cropData?._id! }}
      objectId={cropData?._id}
      onSubmit={refetch}
      useV2
    >
      {cropData?.plots?.some(
        (plot) => plot.isPolygonInPropertyCity === false
      ) && (
        <div style={{ marginBottom: 10 }}>
          <FormWarning
            warning={"Possui polígonos fora da cidade da propriedade"}
          />
        </div>
      )}
      <DetailsSectionColumns columns={cropInfo} />
      {plots && plots.length > 0 && (
        <Row>
          <Column xs={12}>
            <Table
              data={plots}
              columns={[
                { key: "name", label: "Nome" },
                { key: "area", label: "Área" },
                {
                  key: "plantDate",
                  label: "Data do Plantio",
                  special: "date",
                },
                { key: "soilType", label: "Tipo de Solo" },
                { key: "plantMethod", label: "Método do Plantio" },
                { key: "irrigated", label: "Irrigado", special: "boolean" },
                { key: "cropYear", label: "Ano do Plantio" },
                { key: "plantSpacing", label: "Esp. Plantas" },
                { key: "lineSpacing", label: "Esp. Linhas" },
                { key: "seedType", label: "Nome do Cultivar" },
                {
                  key: "isPolygonInPropertyCity",
                  label: "Polígono na cidade da propriedade",
                  special: "boolean",
                },
              ]}
            />
          </Column>
        </Row>
      )}
      {polygons && polygons?.length > 0 && centerMapCoordinates && (
        <Row css={{ height: "50vh" }}>
          <Column xs={12}>
            <Map center={centerMapCoordinates} polygons={polygons} />
          </Column>
        </Row>
      )}
    </EditableSection>
  );
};

export default CropData;
