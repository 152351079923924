import { FC } from "react";
import Row from "../../../Grid/Row";
import Column from "../../../Grid/Column";
import Typography from "../../../Typography";
import { ColorNames } from "../../../../theme";

interface SelectionWarningProps {
  warningText: string;
}

const SelectionWarning: FC<SelectionWarningProps> = ({ warningText }) => {
  return (
    <Row noGutters noMargins>
      <Column>
        <Typography
          variant="textMedium"
          component={"div"}
          align="left"
          color={ColorNames.GREEN}
        >
          {warningText}
        </Typography>
      </Column>
    </Row>
  );
};

export default SelectionWarning;
