/** @jsxImportSource @emotion/react */
import { Outlet, useNavigate, useParams } from "react-router-dom";
import MainFrame from "../../components/MainFrame";
import { useUIStore } from "../../hooks/useUIStore";
import Layout from "../../components/Layout";
import { DocumentsLocation } from "../../Locations";
import {
  ListDocumentsDocument,
  Services,
  useListDocumentsLazyQuery,
} from "../../graphql/generated/types";
import useDocumentsFilter from "../../hooks/useDocumentsFilter";
import documentsTableColumns, {
  mapTableData,
} from "./DocumentsTable/tableColumns";
import TableProvider from "../../contexts/table";
import DocumentsTable from "./DocumentsTable";
import ModalStoreProvider from "../../components/Modal/context/ModalStore";
import { ViewFilterControl } from "../../components/ViewFilterControl";
import { useFlags } from "../../hooks/useFlags";

export const documentsTableId = "documents";

const Documents = () => {
  const { toggleDrawerSize } = useUIStore();
  const navigate = useNavigate();
  const documentFilters = useDocumentsFilter();
  const [listDocumentsQuery] = useListDocumentsLazyQuery();

  const fetchSheetData = async () => {
    const result = await listDocumentsQuery({
      query: ListDocumentsDocument,
      variables: {
        filter: documentFilters.composeQuery(),
      },
    });

    if (result.data?.documents) {
      return mapTableData(result.data.documents);
    }

    return [];
  };
  const drawerParamKey = "docId";
  const { isFlagEnabled } = useFlags();

  const params = useParams();

  const shouldShowViewFilterControl =
    !params[drawerParamKey] && isFlagEnabled("use-quick-filters");

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(DocumentsLocation)}
        onToggle={toggleDrawerSize}
        filter={documentFilters}
        drawerParamKey={drawerParamKey}
        leftSideContent={
          shouldShowViewFilterControl ? (
            <ViewFilterControl
              filters={documentFilters}
              service={Services.Documents}
            />
          ) : undefined
        }
      >
        <ModalStoreProvider>
          <TableProvider
            tableId={documentsTableId}
            allColumns={documentsTableColumns}
            initialSort={{ id: "date", sortKey: "createdAt", direction: -1 }}
            fetchSheetData={fetchSheetData}
          >
            <DocumentsTable filter={documentFilters} />
          </TableProvider>
        </ModalStoreProvider>
      </MainFrame>
    </Layout>
  );
};

export default Documents;
