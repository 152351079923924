import { MetricAggregation } from "../../../graphql/generated/types";
import { formatNumber } from "../../../utils/formatNumber";

export const getTopN = (
  aggregations: MetricAggregation[],
  topN: number,
  othersLabel: string = "Outros"
): MetricAggregation[] => {
  const sortedAggregations = [...aggregations].sort(
    (a, b) => b.total - a.total
  );
  const topElements = sortedAggregations.slice(0, topN);
  const othersTotal = sortedAggregations
    .slice(topN)
    .reduce((sum, agg) => sum + agg.total, 0);
  return [
    ...topElements,
    ...(othersTotal > 0 ? [{ value: othersLabel, total: othersTotal }] : []),
  ];
};

export const defaultFomarter = (value: number) => formatNumber(value, 0, 2);

export const excludeEmpty = (aggregations: MetricAggregation[]) =>
  aggregations.filter((agg) => agg.value !== "");

export const replaceEmpty = (
  aggregations: MetricAggregation[],
  emptyLabel: string
) => aggregations.map((agg) => ({ ...agg, value: agg.value || emptyLabel }));
