import InsuranceCell from "./cells/InsuranceCell";
import StageCell from "./cells/StageCell";
import StatusCell from "./cells/StatusCell";
import {
  ClaimStatus,
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
  ListDocumentsQuery,
} from "../../../graphql/generated/types";
import { TableColumn } from "../../../hooks/useResizeableTable";
import { NullPartial } from "../../../shared/types";
import AvatarCell from "../../../components/ResizableTable/components/cells/AvatarCell";
import ClaimStatusCell from "./cells/ClaimStatusCell";
import { getDocumentPropertyDescription } from "../utils";
import { formatDateWithTime, formatISODate } from "../../../utils/dateUtils";

type DocumentsTableColumns =
  | "id"
  | "farmerName"
  | "farmerCpfCnpj"
  | "stage"
  | "status"
  | "claimStatus"
  | "insuranceTypeGroup"
  | "detail"
  | "area"
  | "premium"
  | "city"
  | "uf"
  | "insuranceCompany"
  | "date"
  | "policyEndDate"
  | "partner"
  | "ope"
  | "com"
  | "sin"
  | "propertyDescription";

export interface DocumentsTableData
  extends NullPartial<Record<DocumentsTableColumns, string>> {
  id: string;
}

const documentsTableColumns: TableColumn<DocumentsTableData>[] = [
  { id: "farmerName", label: "PRODUTOR", width: 200, sortKey: "farmer.name" },
  {
    id: "farmerCpfCnpj",
    label: "CPF/CNPJ",
    sortKey: "farmer.cpfCnpj",
  },
  {
    id: "stage",
    label: "ETAPA",
    cell: (key, value) => (
      <StageCell key={key} stage={value as DocumentStages} />
    ),
    width: 120,
  },
  {
    id: "status",
    label: "STATUS",
    cell: (key, value) => (
      <StatusCell key={key} status={value as DocumentStatus} />
    ),
    width: 120,
  },
  {
    id: "insuranceTypeGroup",
    label: "SEGURO",
    cell: (key, value) => (
      <InsuranceCell
        key={key}
        insuranceType={value as InsuranceTypeGroupEnum}
      />
    ),
    width: 120,
  },
  {
    id: "claimStatus",
    label: "SINISTRO",
    cell: (key, value) => (
      <ClaimStatusCell key={key} status={value as ClaimStatus} />
    ),
  },
  { id: "detail", label: "DETALHE", disableSort: true },
  { id: "area", label: "ÁREA (HA)" },
  {
    id: "premium",
    label: "PRÊMIO (R$)",
    emptyValue: "-",
  },
  { id: "city", label: "CIDADE", sortKey: "property.address.city" },
  { id: "uf", label: "UF", width: 55, sortKey: "property.address.state" },
  { id: "insuranceCompany", label: "SEGURADORA", sortKey: "provider" },
  { id: "date", label: "CRIADO EM", sortKey: "createdAt" },
  { id: "policyEndDate", label: "VIGÊNCIA", sortKey: "policy.endDate" },
  { id: "partner", label: "PARCEIRO", sortKey: "originator.name" },
  {
    id: "ope",
    label: "OPE",
    cell: (key, value) => <AvatarCell key={key} name={value} />,
    width: 70,
    disableSort: true,
  },
  {
    id: "com",
    label: "COM",
    cell: (key, value) => <AvatarCell key={key} name={value} />,
    width: 70,
    disableSort: true,
  },
  {
    id: "sin",
    label: "SIN",
    cell: (key, value) => <AvatarCell key={key} name={value} />,
    width: 70,
    disableSort: true,
  },
  {
    id: "propertyDescription",
    label: "PROPRIEDADE",
    disableSort: true,
  },
];

export const mapTableData = (
  documents?: ListDocumentsQuery["documents"]
): DocumentsTableData[] => {
  return (
    documents?.map((doc) => ({
      id: doc._id!,
      farmerName: doc.farmer?.name,
      farmerCpfCnpj: doc.farmer?.cpfCnpj,
      stage: doc.stage,
      status: doc.status,
      claimStatus: doc.claimStatus,
      insuranceTypeGroup: doc.insuranceTypeGroup,
      detail: doc.description,
      area: doc.area?.toString() || undefined,
      premium: doc.premium?.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      city: doc.properties?.[0]?.address?.city,
      uf: doc.properties?.[0]?.address?.state,
      insuranceCompany: doc.provider?.toUpperCase(),
      date: formatDateWithTime(doc.createdAt),
      policyEndDate: doc.policy?.endDate
        ? formatISODate(doc.policy.endDate)
        : undefined,
      partner: doc.originator?.name?.toUpperCase(),
      ope: doc.operationalResponsible?.name,
      com: doc.commercialResponsible?.name,
      sin: doc.claimResponsible?.name,
      propertyDescription: getDocumentPropertyDescription(doc.properties),
    })) || []
  );
};

export default documentsTableColumns;
