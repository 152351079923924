/** @jsxImportSource @emotion/react */

import React, { FC, HTMLProps, ReactElement, useState } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { css, SerializedStyles } from "@emotion/react";
import Typography, { FontWeights, TextAlign } from "../Typography";
import {
  ColorAlias,
  ColorNames,
  getThemeColorFromAlias,
  theme,
} from "../../theme";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import LoadingSpinner from "../Loading/LoadingSpinner";

const getCSSRules: CSSRulesResolver<{
  color: string;
  props: ButtonProps;
}> = ({ color, props }) => {
  const defaultStyle = {
    fontFamily: "inherit",
    width: props.width || "100%",
    padding: 12,
    height: 45,
    borderRadius: 25,
    border: "1px solid",
    borderColor: color,
    cursor: props.disabled || props.asDisabled ? "not-allowed" : "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    transition: "all 0.2s ease-in-out",
    backgroundColor: props.disabled
      ? theme.colors.LightGrey[100]
      : props.selected
      ? theme.colors.Grey[40]
      : theme.colors.White[100],
    "&:hover": {
      backgroundColor: color || theme.colors.Grey[40],
    },
  };

  return {
    default: defaultStyle,
    outlinePrimary: {
      border: `1px solid ${color}`,
    },
    link: {
      borderColor: "transparent",
      backgroundColor: props.selected
        ? defaultStyle.backgroundColor
        : "transparent",
      "&:hover": {
        backgroundColor: props.disabled ? "transparent" : theme.colors.Grey[40],
      },
    },
    // outlinePrimary: {
    //   ...defaultStyle,
    //   borderColor:
    //     props.disabled || props.asDisabled ? theme.colors.Grey[100] : color,
    //   backgroundColor: theme.colors.White[100],
    //   color: theme.colors.Grey[100],
    //   "&:hover": {
    //     backgroundColor:
    //       props.disabled || props.asDisabled
    //         ? theme.colors.LightGrey[100]
    //         : color,
    //     color: theme.colors.White[100],
    //     borderColor:
    //       props.disabled || props.asDisabled ? theme.colors.Grey[100] : color,
    //   },
    // },
    outlineDark: {
      ...defaultStyle,
      backgroundColor: theme.colors.White[100],
      color: theme.colors.Grey[100],
      borderColor: props.disabled
        ? theme.colors.Grey[100]
        : theme.colors.Black[100],
      "&:hover": {
        backgroundColor: props.disabled
          ? theme.colors.LightGrey[100]
          : theme.colors.Black[100],
        color: props.disabled
          ? theme.colors.Grey[100]
          : theme.colors.White[100],
        borderColor: props.disabled
          ? theme.colors.LightGrey[100]
          : theme.colors.Black[100],
      },
    },
    primaryLink: {
      ...defaultStyle,
      color: color,
      border: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
      "&:hover": {
        weight: "bold",
      },
    },
    darkLink: {
      ...defaultStyle,
      color: theme.colors.Grey[100],
      border: "none",
      backgroundColor: "transparent",
      boxShadow: "none",
      "&:hover": {
        weight: "bold",
      },
    },
    text: {
      width: props.width || "100%",
      verticalAlign: "middle",
      textAlign: props.align,
      marginRight: props.iconName ? 8 : 0,
    },
  };
};

interface ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, "size" | "type" | "style"> {
  text?: string;
  style?: string;
  icon?: ReactElement;
  iconBefore?: boolean;
  align?: TextAlign;
  width?: string | number;
  fontSize?: number;
  weight?: FontWeights;
  iconName?: IconNames;
  asDisabled?: boolean;
  color?: ColorNames;
  selected?: boolean;
  customStyle?: SerializedStyles;
}

const Button: FC<ButtonProps> = (props) => {
  const color = getThemeColorFromAlias(
    props.color as unknown as ColorAlias,
    theme.colors
  )!;
  const styles = useCSSRulesWithTheme(getCSSRules, { color, props });
  const {
    text,
    style = "default",
    icon,
    iconBefore,
    align = "center",
    iconName,
    customStyle,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    asDisabled,
    ...rest
  } = props;
  const [loading, setLoading] = useState(false);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick && e.preventDefault();
    if (loading) return;
    setLoading(true);
    props.onClick && (await props.onClick(e));
    setLoading(false);
  };

  return (
    <button
      css={css(styles.default, styles[style], customStyle)}
      {...rest}
      onClick={handleClick}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Typography
            variant="textLarge"
            weight={props.selected ? "bold" : "regular"}
            align={align}
            uppercase
            color={
              props.disabled
                ? ColorNames.GREY
                : props.selected
                ? props.color
                : ColorNames.BLACK
            }
            css={styles.text}
          >
            {iconBefore && icon} {text} {!iconBefore && icon}
          </Typography>
          {iconName && <Icon name={iconName} />}
        </>
      )}
    </button>
  );
};

export default Button;
