import { DocumentStages } from "../../../graphql/generated/types";
import { theme } from "../../../theme";

export const defaultBackgroundColors = [
  theme.colors.Green[40],
  theme.colors.Pink[40],
  theme.colors.Blue[40],
  "#FF573366", // Bright coral
  "#33FF5766", // Bright lime green
  "#5733FF66", // Vibrant violet
  "#FF33A166", // Hot pink
  "#33FFF166", // Bright teal
  "#F1C40F66", // Bright yellow
  "#E74C3C66", // Bright red-orange
  "#3498DB66", // Bright sky blue
  "#8E44AD66", // Bright purple
  "#1ABC9C66", // Bright cyan
  "#2ECC7166", // Bright green
  "#F39C1266", // Vibrant orange
  "#C0392B66", // Bright crimson
  "#2980B966", // Bright turquoise
  "#9B59B666", // Soft magenta
  "#27AE6066", // Bright forest green
  "#E67E2266", // Bright pumpkin
  "#5DADE266", // Vibrant aqua
];

export const colorByStage: Partial<Record<DocumentStages, string>> = {
  [DocumentStages.Quotation]: theme.colors.Green[40],
  [DocumentStages.Simulation]: theme.colors.Green[40],
  [DocumentStages.Proposal]: theme.colors.Pink[40],
  [DocumentStages.Policy]: theme.colors.Blue[40],
};
