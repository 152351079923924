/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { useCSSRulesWithTheme } from "../../../hooks/useCSSRulesWithTheme";
import Select, { InputActionMeta, MenuPlacement } from "react-select";
import { ColorNames, getThemeColorFromAlias, theme } from "../../../theme";
import useColor from "../../../hooks/useColor";
import Typography, { FontWeight, TypographyVariants } from "../../Typography";
import DropdownIndicator from "./components/DropdownIndicator";
import Option from "./components/Option";
import SingleValue from "./components/SingleValue";
import Placeholder from "./components/Placeholder";
import { IconNames } from "../../Icons/styles/iconNames";
import ValueContainer from "./components/ValueContainer";
import SingleValueClearIndicator from "./components/SingleValueClearIndicator";
import { FormInputOptions } from "../../../graphql/generated/types";
import SelectionWarning from "./components/SelectionWarning";

export type SelectOptionDisplay =
  | "default"
  | "icon"
  | "avatar"
  | "primary"
  | "old";

export type SelectInputOptionInterface = FormInputOptions & {
  iconName?: IconNames;
};

export interface SelectInputProps {
  inputName: string;
  placeholder: string;
  options: SelectInputOptionInterface[];
  value: any;
  // optional props
  color?: ColorNames;
  textVariant?: TypographyVariants;
  weight?: FontWeight;
  defaultValue?: SelectInputOptionInterface;
  disabled?: boolean;
  errorMessage?: string;
  loading?: boolean;
  hideDropdown?: boolean;
  menuPlacement?: MenuPlacement;
  disableSearch?: boolean;
  // optional display props
  optionDisplay?: SelectOptionDisplay;
  // optional actions
  onFocus?: () => void;
  onBlur?: () => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onSelect: (value: SelectInputOptionInterface, inputName: string) => void;
  isClearable?: boolean;
}

export const getSelectStyles = ({ ...props }) => ({
  container: (baseStyle: any) => ({
    ...baseStyle,
    textAlign: "left",
    textTransform: "uppercase",
  }),
  singleValue: (baseStyle: any) => ({
    ...baseStyle,
    color: props.color,
  }),
  input: (baseStyle: any) => ({
    ...baseStyle,
    margin: 0,
    padding: 0,
  }),
  control: (baseStyle: any) => ({
    ...baseStyle,
    boxShadow: props.optionDisplay === "old" ? theme.boxShadow.default : "none",
    borderColor: props.errorMessage
      ? props.colors.ErrorRed[100]
      : props.optionDisplay === "old"
      ? props.colors.Grey[40]
      : props.color,
    borderRadius: props.optionDisplay === "old" ? 5 : 25,
    height: 45,
    cursor: "text",
    backgroundColor:
      props.optionDisplay === "primary"
        ? props.backgroundColor
        : props.colors.White[100],
    "&:hover": {
      color: props.colors.Black[100],
      borderColor: props.optionDisplay === "old" ? undefined : props.color,
      backgroundColor: props.backgroundColor,
    },
    transition: "all 0.2s ease-in-out",
  }),
  option: (baseStyle: any, state: any) => ({
    ...baseStyle,
    cursor: "pointer",
    backgroundColor:
      state.isSelected || state.isFocused
        ? props.backgroundColor
        : "transparent",
    color:
      state.isSelected || state.isFocused
        ? props.colors.Black[100]
        : props.colors.Grey[100],
    "&:active": {
      color: props.color,
      backgroundColor: props.backgroundColor,
    },
  }),
  indicatorSeparator: (baseStyle: any) => ({
    ...baseStyle,
    display: "none",
  }),
  placeholder: (baseStyle: any) => ({
    ...baseStyle,
    color: props.colors.Grey[100],
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
  }),
  menuList: (baseStyle: any) => ({
    ...baseStyle,
    maxHeight: 200,
    zIndex: 1000,
  }),
  valueContainer: (baseStyle: any) => ({
    ...baseStyle,
    padding: 0,
  }),
  menu: (baseStyle: any) => ({
    ...baseStyle,
    border: `1px solid ${props.color}`,
  }),
  dropdownIndicator: (baseStyle: any) => ({
    ...baseStyle,
    padding: "0px 8px 0px 0px",
  }),
});

const SelectInput: FC<SelectInputProps> = ({
  value,
  onSelect,
  isClearable,
  disabled,

  ...props
}) => {
  const {
    color,
    errorMessage,
    options,
    inputName,
    optionDisplay = "default",
    hideDropdown,
    disableSearch,
    menuPlacement,
  } = props;
  const textColor = useColor(color || ColorNames.BLACK);
  const backgroundColor = getThemeColorFromAlias(
    color || ColorNames.GREY,
    theme.colors,
    40
  );

  const styles = useCSSRulesWithTheme(getSelectStyles, {
    color: textColor,
    backgroundColor,
    errorMessage,
    optionDisplay,
  });

  const selectItem = (item: any) => {
    onSelect(item as SelectInputOptionInterface, inputName);
  };

  const warningText = useMemo(() => {
    return options.find((option) => option.value === value)?.warning;
  }, [options, value]);

  return (
    <>
      <Select<SelectInputOptionInterface>
        value={options.find((option) => option.value === value) || null}
        onChange={selectItem}
        optionDisplay={optionDisplay}
        styles={styles}
        isDisabled={disabled}
        isClearable={isClearable}
        menuPlacement={menuPlacement}
        isSearchable={!disableSearch}
        {...props}
        // @ts-ignore
        components={{
          Option,
          SingleValue,
          Placeholder,
          ValueContainer,
          ClearIndicator: SingleValueClearIndicator,
          ...(hideDropdown
            ? { DropdownIndicator: () => null }
            : { DropdownIndicator }),
        }}
      />
      {warningText && <SelectionWarning warningText={warningText} />}
      {errorMessage && (
        <Typography
          variant="textSmall"
          color="ErrorRed"
          align="right"
          component="div"
          css={{ width: "100%" }}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default SelectInput;
