/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import {
  DistributionTotalParams,
  DocumentMetricAggregation,
  DocumentStages,
  useDocumentMetricsQuery,
} from "../../graphql/generated/types";
import { DoughnutChart } from "./Doughnut";
import { Total } from "./Total";
import { documentStatusLabels } from "../../shared/documents/documentStatus";
import { documentStageLabels } from "../../shared/documents/documentStage";
import { formatPrice } from "../../utils/formatNumber";
import { providerLabels } from "../../shared/insurance/providers";
import { useState } from "react";
import { claimStatusLabels } from "../../shared/claims/claimStatus";
import LoadingOverlay from "../Loading/LoadingOverlay";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { colorByStage } from "./utils/colors";
import { insuranceTypeGroupLabels } from "../../shared/insurance/insuranceTypeGroup";
import {
  defaultFomarter,
  excludeEmpty,
  getTopN,
  replaceEmpty,
} from "./utils/formatter";
import { useFlags } from "../../hooks/useFlags";
import { documentRejectReasonLabels } from "../../shared/documents/documentRejectReason";
import { TModelFilter } from "../../hooks/useModelFilter";
import { getFilterWithDefaults } from "./utils/filterDefaults";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  aggregationWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
    gap: "30px",
    paddingBottom: "30px",
    padding: "auto",
  },
  totalsWrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
    gap: "30px",
    paddingBottom: "30px",
    padding: "auto",
  },
};

const metricLabels: Record<DistributionTotalParams, string> = {
  [DistributionTotalParams.Area]: "Área",
  [DistributionTotalParams.Premium]: "Prêmio",
  [DistributionTotalParams.DocCount]: "Número de documentos",
  [DistributionTotalParams.ClaimsTotalPaid]: "Total indenizado",
  [DistributionTotalParams.ClaimCount]: "Número de sinistros",
};

const formatCallbacks: Partial<
  Record<DistributionTotalParams, (value: number) => string>
> = {
  [DistributionTotalParams.Premium]: formatPrice,
  [DistributionTotalParams.Area]: (value) => `${defaultFomarter(value)} ha(s)`,
  [DistributionTotalParams.ClaimsTotalPaid]: formatPrice,
};

interface ChartConfig {
  title: string;
  aggregations: any;
  labelsObject?: Record<string, string>;
  backgroundColor?: string[];
  flag?: string;
}

const chartConfigs = (
  aggregations: DocumentMetricAggregation = {
    stage: [],
    provider: [],
    cultureName: [],
    originatorName: [],
    state: [],
    lastClaimStatus: [],
    insuranceTypeGroup: [],
    status: [],
    rejectReason: [],
  }
): ChartConfig[] => [
  {
    title: "Tipo de seguro",
    aggregations: aggregations.insuranceTypeGroup,
    labelsObject: insuranceTypeGroupLabels,
  },
  aggregations?.stage.length === 1
    ? {
        title: "Status",
        aggregations: aggregations.status,
        labelsObject: documentStatusLabels,
      }
    : {
        title: "Etapa",
        aggregations: aggregations.stage,
        labelsObject: documentStageLabels,
        backgroundColor: aggregations.stage.map(
          (agg) => colorByStage[agg.value as any as DocumentStages]!
        ),
      },
  {
    title: "Seguradora",
    aggregations: excludeEmpty(aggregations?.provider),
    labelsObject: providerLabels,
  },
  {
    title: "Cultura",
    aggregations: excludeEmpty(aggregations.cultureName),
  },
  {
    title: "Parceiro",
    aggregations: getTopN(
      replaceEmpty(aggregations.originatorName, "Sem parceiro"),
      15
    ),
    flag: "admin-only",
  },
  {
    title: "Estado",
    aggregations: aggregations.state,
  },
  {
    title: "Sinistros",
    aggregations: excludeEmpty(aggregations.lastClaimStatus),
    labelsObject: claimStatusLabels,
  },
  {
    title: "Razões de recusa",
    aggregations: excludeEmpty(aggregations.rejectReason),
    labelsObject: documentRejectReasonLabels,
  },
];

export const Metrics = ({ filter }: { filter: TModelFilter }) => {
  const [distributionTotalParam, setDistributionTotalParam] =
    useState<DistributionTotalParams>(DistributionTotalParams.DocCount);

  const finalFilter = getFilterWithDefaults(filter);
  const { loading, ...documentMetricsQuery } = useDocumentMetricsQuery({
    variables: {
      filter: finalFilter,
      distributionTotalParam,
    },
  });
  const { isFlagEnabled } = useFlags();

  const data = loading
    ? documentMetricsQuery.previousData
    : documentMetricsQuery.data;

  const metricLabel = metricLabels[distributionTotalParam];

  return (
    <div css={styles.wrapper}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: "100vh",
            zIndex: 100000,
          }}
        >
          <div style={{ position: "relative", height: "100%" }}>
            <LoadingOverlay>
              <LoadingSpinner />
            </LoadingOverlay>
          </div>
        </div>
      )}
      <div css={styles.totalsWrapper}>
        <Total
          label={"documentos"}
          value={data?.documentMetrics.totals.docCount || 0}
          selected={distributionTotalParam === DistributionTotalParams.DocCount}
          onClick={() =>
            setDistributionTotalParam(DistributionTotalParams.DocCount)
          }
        />
        <Total
          label={"área segurada"}
          value={`${defaultFomarter(
            data?.documentMetrics.totals.area || 0
          )} hectares`}
          selected={distributionTotalParam === DistributionTotalParams.Area}
          onClick={() =>
            setDistributionTotalParam(DistributionTotalParams.Area)
          }
        />
        <Total
          label={"prêmio"}
          value={formatPrice(data?.documentMetrics.totals.premium || 0)}
          selected={distributionTotalParam === DistributionTotalParams.Premium}
          onClick={() =>
            setDistributionTotalParam(DistributionTotalParams.Premium)
          }
        />
        <Total
          label="total indenizado"
          value={formatPrice(data?.documentMetrics.totals.claimsTotalPaid || 0)}
          selected={
            distributionTotalParam === DistributionTotalParams.ClaimsTotalPaid
          }
          onClick={() =>
            setDistributionTotalParam(DistributionTotalParams.ClaimsTotalPaid)
          }
        />
        <Total
          label="número de sinistros"
          value={data?.documentMetrics.totals.claimCount || 0}
          selected={
            distributionTotalParam === DistributionTotalParams.ClaimCount
          }
          onClick={() =>
            setDistributionTotalParam(DistributionTotalParams.ClaimCount)
          }
        />
      </div>
      <div css={styles.aggregationWrapper}>
        {chartConfigs(data?.documentMetrics.aggregations).map(
          (
            { title, aggregations, labelsObject, backgroundColor, flag },
            index
          ) => {
            if (!!flag && !isFlagEnabled(flag)) {
              return null;
            }
            return (
              <DoughnutChart
                key={index}
                formatCallback={formatCallbacks[distributionTotalParam]}
                aggregations={aggregations}
                title={title}
                metricLabel={metricLabel}
                labelsObject={labelsObject}
                backgroundColor={backgroundColor}
              />
            );
          }
        )}
      </div>
    </div>
  );
};
