import { useEffect } from "react";
import { useTableContext } from "../../contexts/table";
import {
  ListPaymentsQuery,
  PaymentStatus,
  useCountPaymentsQuery,
  useListPaymentsQuery,
  useSendPaymentsBillsMutation,
} from "../../graphql/generated/types";
import { TableColumn } from "../../hooks/useResizeableTable";
import { NullPartial } from "../../shared/types";
import ListView from "../../components/ListView/ListView";
import { ToPaymentDetailsLocation } from "../../Locations";
import PaymentCard from "./PaymentCard";
import { formatISODate } from "../../utils/dateUtils";
import { formatPrice } from "../../utils/formatNumber";
import { PaymentStatusLabels } from "../Documents/DocumentDetails/sections/Policy/Payments";
import { SubventionTypeLabels } from "./paymentUtils";
import PageTitle from "../../components/PageTitle";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { toast } from "react-toastify";

type PaymentsTableColumns =
  | "id"
  | "name"
  | "amount"
  | "status"
  | "dateDue"
  | "provider"
  | "subventionType"
  | "docDescription"
  | "responsible"
  | "policyExternalId"
  | "hasBillFile"
  | "sentAt";

export interface PaymentsTableData
  extends NullPartial<Record<PaymentsTableColumns, string | number>> {
  id: string;
}

export const paymentsTableColumns: TableColumn<PaymentsTableData>[] = [
  { id: "name", label: "Nome" },
  { id: "amount", label: "Valor" },
  { id: "status", label: "Status" },
  { id: "dateDue", label: "Data de Vencimento" },
  { id: "provider", label: "Seguradora" },
  { id: "subventionType", label: "Tipo de Subvenção" },
  { id: "docDescription", label: "Descrição do Documento", disableSort: true },
  { id: "responsible", label: "Responsável" },
  {
    id: "policyExternalId",
    label: "Nº Apólice",
    sortKey: "policy.externalId",
  },
  { id: "hasBillFile", label: "Tem Boleto?", disableSort: true },
  { id: "sentAt", label: "Enviado em" },
];

export const mapTableData = (
  payments?: ListPaymentsQuery["payments"]
): PaymentsTableData[] => {
  return (
    payments?.map((payment) => ({
      id: payment._id,
      name: payment.name,
      amount: formatPrice(payment.amount),
      status: PaymentStatusLabels[payment.status],
      dateDue: formatISODate(payment.dateDue),
      docDescription: payment.document?.description,
      responsible: payment.responsible?.name,
      provider: payment.provider.toUpperCase(),
      subventionType: SubventionTypeLabels[payment.subventionType!],
      policyExternalId: payment.document?.policy?.externalId,
      hasBillFile: payment.billFile ? "Sim" : "Não",
      sentAt: formatISODate(payment.sentAt),
    })) || []
  );
};

interface PaymentsTableProps<TFilter> {
  filter: TFilter;
}

const PaymentsList = <TFilter extends { composeQuery: () => any[][] }>({
  filter,
}: PaymentsTableProps<TFilter>) => {
  const { paginator, actions, setData } = useTableContext();
  const { withErrorHandler } = useErrorHandler();
  const selectedRowsIds = Object.keys(actions.selectedRows).filter(
    (key) => !!actions.selectedRows[key] && key !== "all"
  );

  const filterQuery = filter.composeQuery();
  const paymentsQuery = useListPaymentsQuery({
    variables: {
      limit: paginator.limit,
      skip: paginator.skip,
      filter: filterQuery,
      sort: { [actions.sort.sortKey]: actions.sort.direction },
    },
  });

  const countPayments = useCountPaymentsQuery({
    variables: {
      filter: filterQuery,
    },
  });

  useEffect(() => {
    if (paymentsQuery.data?.payments) {
      setData(paymentsQuery.data?.payments);
    }
  }, [paymentsQuery]);

  const [sendPaymentsBillsMutation, { loading: sendingPaymentBills }] =
    useSendPaymentsBillsMutation();

  const handleSendPaymentBills = withErrorHandler(async () => {
    const selectedPayments = paymentsQuery.data?.payments.filter((payment) =>
      selectedRowsIds.includes(payment._id)
    );
    if (
      selectedPayments?.some(
        (payment) =>
          !payment.billFile || payment.status !== PaymentStatus.Pending
      )
    ) {
      return toast.error("Selecione apenas pagamentos pendentes com boletos");
    }
    await sendPaymentsBillsMutation({
      variables: {
        paymentIds: selectedRowsIds,
      },
    });
    await paymentsQuery.refetch();
    await toast.success("Boletos enviados com sucesso");
  }, "Não foi possível enviar os boletos");

  return (
    <>
      <PageTitle title="Pagamentos" />
      <ListView<PaymentsTableData, ListPaymentsQuery["payments"][number]>
        CardComponent={PaymentCard}
        loading={paymentsQuery.loading || sendingPaymentBills}
        redirectLocation={ToPaymentDetailsLocation}
        mapTableData={mapTableData}
        total={countPayments.data?.countPayments || 0}
        bulkActions={[
          {
            label: "ENVIAR BOLETOS",
            onClick: handleSendPaymentBills,
          },
        ]}
      />
    </>
  );
};

export default PaymentsList;
