import { useCallback, useEffect, useState } from "react";
import { TFilter, TFilterOption } from "../filters/types";
import { useAuth } from "./useAuth";
import { useApolloClient } from "@apollo/client";

const useModelFilter = (
  filterFunction: () => TFilter[],
  optionLoaders: (() => Promise<[string, TFilterOption[]]>)[],
  countQuery?: any
) => {
  const [filters, setFilters] = useState<TFilter[]>(filterFunction());
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { loaded: authIsloaded } = useAuth();
  const apolloClient = useApolloClient();

  const setFilterOptions = useCallback(
    (filterName: string, options: TFilterOption[]) => {
      setFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.name === filterName
            ? {
                ...filter,
                options: [
                  ...filter.options.filter(
                    (prevOpt) =>
                      !options.some((newOpt) => newOpt.value === prevOpt.value)
                  ),
                  ...options,
                ],
              }
            : filter
        )
      );
    },
    [setFilters]
  );

  useEffect(() => {
    if (authIsloaded) {
      optionLoaders.forEach(async (loader) => {
        const [filterName, options] = await loader();
        setFilterOptions(filterName, options);
      });
    }
  }, [authIsloaded]);

  const setFilterValue = useCallback(
    (filterName: string, value: string[]) => {
      setFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.name === filterName ? { ...filter, value } : filter
        )
      );
    },
    [setFilters]
  );

  const filterValues = filters.map((filter) => filter.value);
  const filterOptions = filters.map((filter) => filter.options);

  const composeQuery = useCallback(() => {
    const andConditions: any[][] = [];
    filters.forEach((filter) => {
      const orConditions = filter.value.map((value) => {
        if (filter.dateDisplay) return { [filter.name]: value };
        const option = filter.options.find((opt) => opt.value === value);
        if (option) return option.filter;
        if (filter.toFilter) return filter.toFilter(value as string);
        return {};
      });
      if (orConditions.length) {
        andConditions.push(orConditions);
      }
    });
    if (searchTerm) {
      andConditions.push([{ searchTerm }]);
    }
    return andConditions;
  }, [searchTerm, ...filterValues, ...filterOptions]);

  const getOption = useCallback(
    (filterName: string, optionValue: string) => {
      const filter = filters.find((filter) => filter.name === filterName);
      return (
        filter?.options.find((option) => option.value === optionValue) || null
      );
    },
    [filters]
  );

  const getCount = useCallback(
    async (filters: [string, string][]): Promise<number> => {
      const filterQuery = filters
        .map(([filterName, optionValue]) => [
          getOption(filterName, optionValue)?.filter,
        ])
        .filter((filter) => filter[0]);
      const res = await apolloClient.query({
        query: countQuery,
        variables: {
          filter: filterQuery,
        },
      });
      return Object.values(res.data)[0] as number;
    },
    [apolloClient, countQuery]
  );

  const getFilterAndOption = useCallback(
    (
      filterName: string,
      optionValue: string
    ): { filter: TFilter; option: TFilterOption } => {
      const filter = filters.find((filter) => filter.name === filterName)!;
      const option = filter?.options.find(
        (option) => option.value === optionValue
      )!;
      return { filter, option };
    },
    [filters]
  );

  const getFilter = useCallback(
    (filterName: string): TFilter => {
      return filters.find((filter) => filter.name === filterName)!;
    },
    [filters]
  );

  return {
    filters,
    setFilterValue,
    composeQuery,
    filterValues,
    searchTerm,
    setSearchTerm,
    getCount,
    getFilterAndOption,
    getFilter,
    getOption,
    setFilters,
  };
};

export default useModelFilter;
export type TModelFilter = ReturnType<typeof useModelFilter>;
