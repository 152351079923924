import { FC } from "react";

import { useListContactsLazyQuery } from "../../../graphql/generated/types";
import Layout from "../../../components/Layout";
import { ContactsLocation, NewContactLocation } from "../../../Locations";
import MainFrame from "../../../components/MainFrame";
import { Outlet, useNavigate } from "react-router-dom";
import { useUIStore } from "../../../hooks/useUIStore";
import useContactsFilter from "../../../hooks/useContactsFilter";
import TableProvider from "../../../contexts/table";
import contactsTableColumns, {
  mapTableData,
} from "./ContactsTable/tableColumns";
import ContactsTable from "./ContactsTable/ContactsTable";

const Contacts: FC = () => {
  const contactsFilter = useContactsFilter();
  const filter = contactsFilter.composeQuery();
  const navigate = useNavigate();
  const { toggleDrawerSize } = useUIStore();
  const [listContactsQuery] = useListContactsLazyQuery();

  const fetchSheetData = async () => {
    const result = await listContactsQuery({
      variables: {
        filter,
      },
    });

    if (result.data?.contacts) {
      return mapTableData(result.data.contacts);
    }

    return [];
  };

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(ContactsLocation)}
        onToggle={toggleDrawerSize}
        drawerParamKey="contactId"
        filter={contactsFilter}
        createLink={NewContactLocation}
      >
        <TableProvider
          allColumns={contactsTableColumns}
          initialSort={{ id: "date", sortKey: "createdAt", direction: -1 }}
          fetchSheetData={fetchSheetData}
          tableId="contacts"
        >
          <ContactsTable filter={contactsFilter} />
        </TableProvider>
      </MainFrame>
    </Layout>
  );
};

export default Contacts;
