/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { theme } from "../../../../theme";
import { useFlags } from "../../../../hooks/useFlags";
import { useTableContext } from "../../../../contexts/table";
import { documentsTableId } from "../../../../views/Documents";

export interface TableSwithProps {
  active: string;
  onClick: (id: string) => void;
}

const getStyles = () => ({
  container: css`
    display: flex;
    width: fit-content;
    padding: 4px;
    border-radius: 10px;
    border: 1px solid grey;
    gap: 2px;
  `,
  button: css`
    font-weight: bold;
    padding: 8px 6px 6px 6px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    min-width: 40px;
    &.active {
      background-color: ${theme.colors.Grey[20]};
    }
  `,
});

const TableSwitch: React.FC<TableSwithProps> = ({ active, onClick }) => {
  const styles = getStyles();
  const { isFlagEnabled } = useFlags();
  const { tableId } = useTableContext();
  return (
    <div css={styles.container}>
      <div
        id="table"
        css={styles.button}
        className={active === "table" ? "active" : undefined}
        onClick={(e) => onClick(e.currentTarget.id)}
      >
        tabela
      </div>
      <div
        id="card"
        css={styles.button}
        className={active === "card" ? "active" : undefined}
        onClick={(e) => onClick(e.currentTarget.id)}
      >
        card
      </div>
      {isFlagEnabled("use-documents-dashboard") &&
        tableId === documentsTableId && (
          <div
            id="metrics"
            css={styles.button}
            className={active === "metrics" ? "active" : undefined}
            onClick={(e) => onClick(e.currentTarget.id)}
          >
            análise
          </div>
        )}
    </div>
  );
};

export default TableSwitch;
