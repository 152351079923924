import { FC } from "react";
import EditableTable from "../../../../../components/EditableTable";
import useCultureDetails from "../../../hooks/useCulture";
import { GetUpsertHarvestFormResponseDocument } from "../../../../../graphql/generated/types";
import { HarvestDetails } from "./HarvestDetails";

export const Harvests: FC<{}> = () => {
  const { culture, refetch } = useCultureDetails();
  return (
    <EditableTable
      withBorder
      formTitle="Criar safra"
      data={culture.harvests || []}
      createQuery={GetUpsertHarvestFormResponseDocument}
      createPayload={{ cultureId: culture._id }}
      formDisplay="modal"
      onSubmit={refetch}
      columns={[
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "Ativo",
          key: "active",
          special: "boolean",
        },
      ]}
      useV2
      DetailsComponent={HarvestDetails}
    />
  );
};
