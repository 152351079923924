import { FC, useMemo } from "react";
import Sidebar from "../../../components/Sidebar";
import Summary from "./components/Summary";
import AllianzSettings from "./settings/Allianz/Table";
import { Harvests } from "./components/Harvests";
import useCultureDetails from "../hooks/useCulture";
import PageTitle from "../../../components/PageTitle";
import SwissSettingsTable from "./settings/Swiss/Table";
import MapfreSettingsTable from "./settings/Mapfre/Table";

const CultureFlow: FC = () => {
  const menuItems = useMemo(() => {
    return [
      {
        title: "Geral",
        Component: Summary,
      },
      {
        title: "Allianz",
        Component: AllianzSettings,
      },
      {
        title: "Swiss",
        Component: SwissSettingsTable,
      },
      {
        title: "Mapfre",
        Component: MapfreSettingsTable,
      },
      { title: "Safras", Component: Harvests },
    ];
  }, []);
  const { culture } = useCultureDetails();
  return (
    <>
      <PageTitle title={"Cultura - " + culture.name!} />
      <Sidebar menuItems={menuItems} />;
    </>
  );
};

export default CultureFlow;
