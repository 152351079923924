/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { FC, useEffect, useState, KeyboardEvent } from "react";

import TextInput from "../Input/TextInput";
import { TModelFilter } from "../../hooks/useModelFilter";
import {
  Services,
  useListViewFiltersQuery,
  useUpsertViewFilterMutation,
} from "../../graphql/generated/types";
import Loading from "../Loading";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { BaseFontSize } from "../Typography";
import { ColorNames } from "../../theme";
import { FilterOption, FilterSelect } from "./FilterSelect";

const styles: CSSObject = {
  wrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "start",
    gap: "8px",
    color: "#000",
  },
  input: {
    width: "200px",
  },
  inputIconsWrapper: {
    display: "flex",
    gap: "6px",
    justifyContent: "end",
    marginTop: "2px",
  },
  inputIcon: {
    backgroundColor: ColorNames.WHITE,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.15)",
    cursor: "pointer",
    padding: 3,
    borderRadius: 6,
    transition: "all 0.2s ease",

    "&:hover": {
      backgroundColor: "#f8f8f8",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
    },
    "&:active": {
      boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f1f1f1",
    },
  },
};

type EditModeType = "rename" | "create" | null;

export const ViewFilterControl: FC<{
  filters: TModelFilter;
  service: Services;
}> = ({ filters, service }) => {
  const [filterName, setFilterName] = useState<string>("");
  const [selectedFilterId, setSelectedFilterId] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<EditModeType>(null);

  const filtersQuery = useListViewFiltersQuery();
  const [upsertFilterMutation, { loading: upserting }] =
    useUpsertViewFilterMutation();

  const filterList = filtersQuery.data?.viewFilters || [];
  const selectedFilter =
    filterList.find((f) => f._id === selectedFilterId) || null;

  // Build the dropdown options
  const filterOptions: FilterOption[] = filterList.map((f) => ({
    value: f._id,
    label: f.name,
  }));

  // Reset filter name if we're exiting create/rename
  useEffect(() => {
    if (editMode === null) {
      setFilterName("");
    }
  }, [editMode]);

  // Sync parent's filters when we pick a different filter
  useEffect(() => {
    if (selectedFilter) {
      const newFiltersValues = filters.filters.map((currentFilter) => {
        const filterValue = selectedFilter.filters.find(
          (ff) => ff.name === currentFilter.name
        )?.value;
        return {
          ...currentFilter,
          value: filterValue || [],
        };
      });
      filters.setFilters(newFiltersValues);
    }
  }, [selectedFilter]);

  // Build payload for upserting
  const getFiltersPayload = () => {
    return filters.filters
      .filter((currentFilter) => currentFilter.value.length > 0)
      .map((currentFilter) => ({
        name: currentFilter.name,
        value: currentFilter.value,
      }));
  };

  // "Novo filtro"
  const handleEnterCreateMode = () => {
    setFilterName("");
    setEditMode("create");
  };

  // "Rename" on double-click
  const handleEnterRenameMode = () => {
    if (!selectedFilter) return;
    setFilterName(selectedFilter.name);
    setEditMode("rename");
  };

  const handleSave = async () => {
    let newFilterId: string | undefined;

    if (editMode === "create") {
      // Create new filter
      const result = await upsertFilterMutation({
        variables: {
          input: {
            _id: undefined,
            filters: getFiltersPayload(),
            name: filterName || "Sem título",
            service,
          },
        },
      });
      newFilterId = result.data?.upsertViewFilter?._id;
    } else if (editMode === "rename" && selectedFilter) {
      // Rename existing
      const result = await upsertFilterMutation({
        variables: {
          input: {
            _id: selectedFilter._id,
            filters: getFiltersPayload(),
            name: filterName || selectedFilter.name,
            service,
          },
        },
      });
      newFilterId = result.data?.upsertViewFilter?._id;
    }

    await filtersQuery.refetch();

    if (newFilterId) {
      setSelectedFilterId(newFilterId);
    }
    setEditMode(null);
  };

  const handleSkip = () => {
    setEditMode(null);
  };

  const handleUpdateSelectedFilter = async () => {
    if (!selectedFilter) return;
    await upsertFilterMutation({
      variables: {
        input: {
          _id: selectedFilter._id,
          filters: getFiltersPayload(),
          name: selectedFilter.name,
          service,
        },
      },
    });
    await filtersQuery.refetch();
  };

  const handleDelete = async () => {
    if (!selectedFilter) return;
    await upsertFilterMutation({
      variables: {
        input: {
          _id: selectedFilter._id,
          name: selectedFilter.name,
          service,
          archived: true,
        },
      },
    });
    await filtersQuery.refetch();
    setSelectedFilterId(null);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    } else if (e.key === "Escape") {
      handleSkip();
    }
  };

  if (upserting) {
    return <Loading size="extraLarge" />;
  }

  return (
    <div css={styles.wrapper}>
      {editMode === null ? (
        // NORMAL MODE
        <>
          <FilterSelect
            onDoubleClick={() => {
              if (selectedFilterId) {
                handleEnterRenameMode();
              }
            }}
            options={filterOptions}
            onChange={(newValue) => {
              setSelectedFilterId(newValue ? newValue.value : null);
            }}
            value={
              filterOptions.find((fo) => fo.value === selectedFilterId) || null
            }
          />
          {selectedFilterId && (
            <>
              <Icon
                name={IconNames.Delete}
                size={BaseFontSize * 1.2}
                onClick={handleDelete}
                tooltipText="Excluir"
              />
              <Icon
                name={IconNames.Save}
                size={BaseFontSize * 1.2}
                onClick={handleUpdateSelectedFilter}
                tooltipText="Salvar"
              />
            </>
          )}
          <Icon
            name={IconNames.Add}
            size={BaseFontSize * 1.2}
            onClick={handleEnterCreateMode}
            tooltipText="Novo"
          />
        </>
      ) : (
        // EDIT MODE
        <div css={styles.input}>
          <TextInput
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            placeholder={
              editMode === "create"
                ? "Digite o nome do seu filtro"
                : "Renomear filtro"
            }
            width="100%"
            autoFocus
            onKeyDown={handleKeyDown}
          />
          <div css={styles.inputIconsWrapper}>
            <Icon
              css={styles.inputIcon}
              name={IconNames.Close}
              onClick={handleSkip}
              size={BaseFontSize * 1.1}
            />
            <Icon
              name={IconNames.Confirm}
              onClick={handleSave}
              size={BaseFontSize * 1.1}
              css={styles.inputIcon}
            />
          </div>
        </div>
      )}
    </div>
  );
};
