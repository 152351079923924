import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { PaymentSummary } from "./components/PaymentSummary";
import {
  BasePaymentFieldsFragment,
  useGetPaymentQuery,
} from "../../graphql/generated/types";
import Loading from "../../components/Loading";

const PaymentDetails: FC = () => {
  const { paymentId } = useParams();
  const { loading, data, refetch } = useGetPaymentQuery({
    variables: {
      paymentId: paymentId as string,
    },
  });

  const menuItems = useMemo(() => {
    return [
      {
        title: "Geral",
        Component: () => (
          <PaymentSummary
            payment={data?.payment as BasePaymentFieldsFragment}
            refetch={refetch}
          />
        ),
      },
    ];
  }, [data?.payment]);
  if (loading || !data) return <Loading size="extraLarge" />;
  return <Sidebar menuItems={menuItems} />;
};

export default PaymentDetails;
