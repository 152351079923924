import { Property } from "../../graphql/generated/types";

export const getDocumentPropertyDescription = (
  properties?: Property[] | null
) => {
  if (properties?.length === 1) {
    return properties[0].displayName;
  }
  return `${properties?.length} propriedades`;
};
