import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { PaginationContext } from "../hooks/usePagination";
import useTable from "../hooks/useTable";
import { ResizeTableContext, TableColumn } from "../hooks/useResizeableTable";
import { TableActionsContext } from "../hooks/useTableActions";
import { SortOption } from "../components/ResizableTable";

interface TableContextType<C = any, D = any> {
  paginator: PaginationContext;
  resize: ResizeTableContext<C>;
  actions: TableActionsContext<C>;
  data?: D[];
  setData: Dispatch<SetStateAction<D[] | undefined>>;
  tableId: string;
}

const TableContext = createContext<TableContextType | undefined>(undefined);

export const useTableContext = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error("useTable must be used within a TableProvider");
  }
  return context;
};

interface TableProviderProps<C> extends PropsWithChildren {
  allColumns: TableColumn<C>[];
  initialSort: SortOption;
  fetchSheetData?: () => Promise<C[]>;
  tableId: string;
}

const TableProvider = <C, D extends { _id: string }>({
  children,
  allColumns,
  initialSort,
  fetchSheetData,
  tableId,
}: TableProviderProps<C>) => {
  const [data, setData] = useState<D[]>();
  const { paginator, resize, actions } = useTable(
    allColumns,
    initialSort,
    tableId,
    data,
    fetchSheetData
  );
  return (
    <TableContext.Provider
      value={{
        paginator,
        resize,
        actions,
        data,
        setData,
        tableId,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
