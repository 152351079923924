import * as locations from "../Locations";

export const locationLabels: { [key: string]: string } = {
  [locations.HomeLocation]: "Home",
  [locations.DocumentsDashboardLocation]: "Seguros",
  [locations.PartnersDashboardLocation]: "Parceiros",
  [locations.DocumentsLocation]: "Documentos",
  [locations.ConsultantsLocation]: "Contatos",
  [locations.DocumentDetailsLocation]: "Documento",
  [locations.DocumentDetailsNotesTabLocation]: "Notas do Documento",
  [locations.DocumentDetailsChatTabLocation]: "Chat do Documento",
  [locations.SimulationLocation]: "Simulação",
  [locations.DirectSimulationLocation]: "Simulação",
  [locations.DirectQuotationFormLocation]: "Cotação",
  [locations.TermsAndConditionsLocation]: "Termos e condições",
  [locations.LoginLocation]: "Login",
  [locations.NfeUploadFormLocation]: "Anexar notas fiscais",
  [locations.RegisterLocation]: "Cadastro",
  [locations.PendingRegisterLocation]: "Cadastro pendente",
  [locations.ContactsLocation]: "Contatos",
  [locations.ContactDetailsLocation]: "Produtor",
  [locations.ContactDetailsChatLocation]: "Chat",
  [locations.KmlUploadFormLocation]: "Upload do KML",
  [locations.ContactDetailsNotesLocation]: "Notas",
  [locations.CultureListLocation]: "Culturas",
  [locations.CultureDetailLocation]: "Cultura",
  [locations.NewContactLocation]: "Novo contato",
};
