import { FC, useMemo } from "react";
import {
  GetEditPaymentOnDocumentFormResponseDocument,
  GetEditPaymentOnDocumentFormResponseQueryVariables,
  Payment,
} from "../../../../../../graphql/generated/types";
import EditableSection from "../../../../../../components/EditableSection";
import { useModalStore } from "../../../../../../components/Modal/context/ModalStore";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import DetailsSectionColumns from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { ToPaymentDetailsLocation } from "../../../../../../Locations";
import { getPaymentDetailsColumns } from "../../../../../Payments/paymentUtils";

interface PaymentProps {
  data: Payment;
}

const PaymentFC: FC<PaymentProps> = ({ data: payment }) => {
  const { refetch } = useDocumentDetails();
  const { rightSideColumn, leftSideColumn } = useMemo(
    () => getPaymentDetailsColumns(payment),
    [payment]
  );

  const { closeModal } = useModalStore();

  return (
    <EditableSection<{}, GetEditPaymentOnDocumentFormResponseQueryVariables>
      title="Parcela"
      main
      editQuery={GetEditPaymentOnDocumentFormResponseDocument}
      editFlag="admin-only"
      objectId={payment._id}
      onSubmit={() => {
        refetch(), closeModal();
      }}
      footerButtons={[
        {
          link: ToPaymentDetailsLocation(payment._id),
          text: "Ver detalhes",
          flag: "admin-only",
        },
      ]}
    >
      <DetailsSectionColumns columns={[leftSideColumn, rightSideColumn]} />
    </EditableSection>
  );
};

export default PaymentFC;
