/** @jsxImportSource @emotion/react */

import { FC, useEffect, useMemo, useState } from "react";
import { TModelFilter } from "../../hooks/useModelFilter";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ColorNames, theme } from "../../theme";
import ScrollContainer from "../ScrollContainer";
import { headerHeight } from "../Layout/components/Header";
import { totalBreadcrumbsHeight } from "../Breadcrumbs";
import useOutsideClick from "../../hooks/useOutsideClick";
import Filters from "./Filters";
import BasicSearch from "./BasicSearch";
import { Tags } from "../Tags";
import { useGetMany2XOptionsLazyQuery } from "../../graphql/generated/types";
import { valueToDates } from "../DateFilter/dateFilterUtils";
import { formatDateWithTime, formatISODate } from "../../utils/dateUtils";
import { DateDisplays } from "../../filters/types";

const styles = {
  filters: {
    position: "absolute" as const,
    top: totalBreadcrumbsHeight,
    right: 0,
    backgroundColor: theme.colors.White[100],
    zIndex: 100,
    width: "100%",
    // fix this properly later plz
    height: `calc(100vh - ${headerHeight + totalBreadcrumbsHeight + 110}px)`,
    padding: 24,
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box" as const,
    borderRadius: 10,
    border: "1px solid",
    borderColor: theme.colors.Black[100],
  },
  filterCount: {
    position: "absolute" as const,
    top: 0,
    right: 0,
    backgroundColor: theme.colors.Green[100],
    boxShadow: theme.boxShadow.default,
    color: theme.colors.White[100],
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: "bold",
  },
};

interface SearchBarProps {
  filter: TModelFilter;
}

type FilterTag = {
  filterName: string;
  value: string;
  label: string;
};

const SearchBar: FC<SearchBarProps> = ({ filter }) => {
  const [showFilters, setShowFilters] = useState(false);

  const ref = useOutsideClick(() => {
    setShowFilters(false);
  });
  const filterCount = useMemo(() => {
    return filter.filters.filter((filter) => filter.value.length > 0).length;
  }, [filter.filters]);

  const [getMany2xOptions] = useGetMany2XOptionsLazyQuery();
  const [tags, setTags] = useState<FilterTag[]>([]);
  useEffect(() => {
    let isMounted = true; // To ensure component is still mounted when setting state.

    async function fetchTags() {
      // Wrap the entire logic in an async function
      const allTagGroups = await Promise.all(
        filter.filters.map(async (currentFilter) => {
          const items = await Promise.all(
            currentFilter.value.map(async (value) => {
              const option = filter.getOption(currentFilter.name, value);
              let item = {
                filterName: currentFilter.name,
                value,
                label: "",
              };

              if (option) {
                // We have a local option
                item.label = option.label;
              } else if (currentFilter.many2Many) {
                // We need to fetch from getMany2xOptions
                const m2mRes = await getMany2xOptions({
                  variables: {
                    serviceName: currentFilter.many2Many.serviceName,
                    domain: [],
                    searchTerm: "",
                    currentValue: value,
                  },
                });
                if (m2mRes.data?.getMany2XOptions?.length) {
                  item.label = `${currentFilter.label}: ${m2mRes.data.getMany2XOptions[0].label}`;
                }
              }
              // If there's a date display override
              else if (currentFilter.dateDisplay) {
                const dateValues = valueToDates(
                  [value],
                  currentFilter.dateDisplay
                );
                const dateLabelValues: string[] = [];
                if (dateValues.gte) {
                  const formatedDate =
                    currentFilter.dateDisplay === DateDisplays.onlyDate
                      ? formatISODate(dateValues.gte)
                      : formatDateWithTime(dateValues.gte);
                  dateLabelValues.push(`a partir de ${formatedDate}`);
                }
                if (dateValues.lte) {
                  const formatedDate =
                    currentFilter.dateDisplay === DateDisplays.onlyDate
                      ? formatISODate(dateValues.lte)
                      : formatDateWithTime(dateValues.lte);
                  dateLabelValues.push(`até ${formatedDate}`);
                }
                if (dateLabelValues.length) {
                  item.label = `${currentFilter.label}: ${dateLabelValues.join(
                    " e "
                  )}`;
                }
              }

              return item;
            })
          );

          return items.filter(Boolean); // Remove any undefined or null items
        })
      );

      const tags = allTagGroups.flat();
      if (isMounted) {
        setTags(tags);
      }
    }

    fetchTags();

    return () => {
      isMounted = false;
    };
  }, [filter, getMany2xOptions]);

  return (
    <div ref={ref} css={{ height: "100%", position: "relative" }}>
      {filterCount > 0 && <div css={styles.filterCount}>{filterCount}</div>}
      <BasicSearch
        searchTerm={filter.searchTerm}
        setSearchTerm={filter.setSearchTerm}
      >
        <Icon
          name={IconNames.Filter}
          color={ColorNames.BLACK}
          onClick={() => setShowFilters(!showFilters)}
        />
      </BasicSearch>
      {showFilters && (
        <div css={styles.filters}>
          <ScrollContainer height="100%">
            <Filters filter={filter} />
          </ScrollContainer>
        </div>
      )}
      <Tags
        tags={tags}
        removeCallback={(tag) => {
          const currentValue = filter.getFilter(tag.filterName).value;
          let newValue = [...currentValue];
          newValue = currentValue.filter((value) => value !== tag.value);
          filter.setFilterValue(tag.filterName, newValue);
        }}
      />
    </div>
  );
};

export default SearchBar;
