import { IconNames } from "../components/Icons/styles/iconNames";
import {
  MultiSelectMenuListDisplay,
  MultiSelectValueDisplay,
} from "../components/Input/SelectInputs/MultiSelectInput";
import { SelectOptionDisplay } from "../components/Input/SelectInputs/SelectInput";
import { Services } from "../graphql/generated/types";

export type TFilterOption<OptionNames extends string = string> = {
  name?: OptionNames;
  value: string;
  label: string;
  filter: any;
  iconName?: IconNames;
  disabled?: boolean;
};

type TMany2ManyFilter = {
  serviceName: Services;
  domain: any;
};

export enum DateDisplays {
  onlyDate = "onlyDate",
  dateWithTime = "dateWithTime",
}

export type TFilter<OptionNames extends string = string> = {
  label: string;
  value: string[];
  urlParam: string;
  name: string;
  options: TFilterOption<OptionNames>[];
  many2Many?: TMany2ManyFilter;
  selectMultiValueDisplay?: MultiSelectValueDisplay;
  selectMenuListDisplay?: MultiSelectMenuListDisplay;
  selectOptionDisplay?: SelectOptionDisplay;
  dateDisplay?: DateDisplays;
  toFilter?: (value: string) => Record<string, string>;
};
