/** @jsxImportSource @emotion/react */

import React, { ReactNode, FC, useEffect, useMemo } from "react";
import Column from "../../components/Grid/Column";
import { useUIStore } from "../../hooks/useUIStore";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Row from "../Grid/Row";
import Breadcrumbs, { totalBreadcrumbsHeight } from "../Breadcrumbs";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import { TModelFilter } from "../../hooks/useModelFilter";
import useIsMobile from "../../hooks/useIsMobile";
import { useLocation, useParams } from "react-router-dom";
import { mainFrameBreadCrumbs } from "./assets/breadcrumbs";
import { DocumentsDashboardLocation } from "../../Locations";
import SearchBar from "../SearchBar";
import { IconNames } from "../Icons/styles/iconNames";
import CustomLink from "../CustomLink";
import Button from "../Button";

interface MainFrameProps {
  children: ReactNode;
  drawerContent?: React.ReactElement | null | any;
  onClose?: () => void;
  onToggle?: () => void;
  filter?: TModelFilter;
  drawerParamKey?: string;
  createLink?: string;
  leftSideContent?: ReactNode;
}

const getCSSRules = ({ subtractHeight }: { subtractHeight: number }) => ({
  wrapper: {
    height: "100%",
    position: "relative" as const,
  },
  mainContent: {
    marginBottom: 0,
    height: `calc(100% - ${subtractHeight}px)`,
  },
  leftColumn: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
  },
  cardsContainer: {
    height: "100%",
  },
  drawerContainer: {
    height: "100%",
    boxSizing: "border-box" as "border-box",
    overflow: "hidden",
  },
  actionsButtonsContainer: {
    display: "flex",
    width: "100%",
    gap: 12,
    justifyContent: "end",
    marginBottom: 12,
  },
  createColumn: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 5,
  },
});

const MainFrame: FC<MainFrameProps> = ({
  drawerContent,
  children,
  filter,
  // when u define a drawer route, what is the key u are using to refer ur model id in ur location
  createLink,
  leftSideContent,
  drawerParamKey = "docId",
}) => {
  const { isDrawerOpen, isDrawerFullWidth, openDrawer, closeDrawer } =
    useUIStore();
  const {
    localErrors: { quotationsList: quotationsError },
    dismissError,
  } = useErrorHandler();
  const isMobile = useIsMobile();
  const location = useLocation();

  const subtractHeight = useMemo(() => {
    if (
      (isMobile && location.pathname === DocumentsDashboardLocation) ||
      !isMobile
    ) {
      return totalBreadcrumbsHeight;
    }
    return 0;
  }, [isMobile, isDrawerOpen, filter]);

  const styles = useCSSRulesWithTheme(getCSSRules, { subtractHeight });

  const params = useParams();

  useEffect(() => {
    if (params[drawerParamKey] && (!isDrawerOpen || isMobile)) {
      openDrawer();
    }
    if (!params[drawerParamKey] && isDrawerOpen) {
      closeDrawer();
    }
  }, [params, isMobile, isDrawerOpen]);

  const shouldShowRightSideContent = !isDrawerOpen && !isMobile;

  return (
    <div css={styles.wrapper}>
      <Row css={{ margin: 0 }}>
        <Column xs={12} lg={7}>
          {!isMobile &&
            (leftSideContent || (
              <div css={{ display: "flex", justifyContent: "space-between" }}>
                <Breadcrumbs
                  items={mainFrameBreadCrumbs}
                  drawerParamKey={drawerParamKey}
                />
              </div>
            ))}
        </Column>
        {filter && shouldShowRightSideContent && (
          <Column xs={12} lg={5}>
            <Row>
              {createLink && (
                <Column xs={4}>
                  <CustomLink to={createLink}>
                    <Button iconName={IconNames.Add} text="Criar" width={150} />
                  </CustomLink>
                </Column>
              )}
              <Column xs={createLink ? 8 : 12}>
                <SearchBar filter={filter} />
              </Column>
            </Row>
          </Column>
        )}
        {!filter && createLink && shouldShowRightSideContent && (
          <Column
            xs={12}
            css={{
              display: "flex",
              justifyContent: "end",
              marginBottom: 15,
            }}
          >
            <CustomLink to={createLink}>
              <Button iconName={IconNames.Add} text="Criar" width={150} />
            </CustomLink>
          </Column>
        )}
      </Row>
      {quotationsError && (
        <ErrorMessage
          message={quotationsError}
          handleClose={() => dismissError("quotationsList")}
        />
      )}
      <Row css={styles.mainContent}>
        {!isDrawerFullWidth && (
          <Column xs={12} md={isDrawerOpen ? 4 : 12} css={styles.leftColumn}>
            {children}
          </Column>
        )}
        {isDrawerOpen && (
          <Column xs={12} css={styles.drawerContainer}>
            {drawerContent}
          </Column>
        )}
      </Row>
    </div>
  );
};

export default MainFrame;
