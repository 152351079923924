/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { ColorNames, theme } from "../../theme";
import { BaseFontSize } from "../Typography";

export type Tag = {
  label: string;
};

type TagsProps<T extends Tag> = {
  tags: T[];
  removeCallback: (tag: T) => void;
};

const styles: Record<string, CSSObject> = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "6px",
    paddingTop: "6px",
  },
  tagContainer: {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: 6,
    overflow: "hidden", // ensures corners look clean
  },
  textSection: {
    backgroundColor: theme.colors.Black[80],
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    padding: "7px 10px",
    color: "white",
    fontSize: "12px",
    lineHeight: 1.2,
    fontWeight: "bold",
  },
  closeSection: {
    backgroundColor: theme.colors.Grey[80],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 4px",
    cursor: "pointer",
    height: "100%",
    "&:hover": {
      backgroundColor: theme.colors.Grey[60],
    },
  },
};

export const Tags = <T extends Tag>({ tags, removeCallback }: TagsProps<T>) => {
  if (!tags.length) return null;

  return (
    <div css={styles.wrapper}>
      {tags.map((tag, index) => (
        <div css={styles.tagContainer} key={index}>
          <div css={styles.textSection}>{tag.label}</div>
          <div css={styles.closeSection} onClick={() => removeCallback(tag)}>
            <Icon
              name={IconNames.Close}
              size={0.8 * BaseFontSize}
              color={ColorNames.BLACK}
              css={{ cursor: "pointer" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
